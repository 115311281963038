import gql from 'graphql-tag';
import { ReactElement, useEffect, useState } from 'react';
import { Menu, MenuProps, Skeleton, Switch, Tabs } from 'antd';
import { projectService } from 'services/project.service';
import { useHistory, useParams } from 'react-router-dom';
import { NewService } from './NewService';
import { useLazyQuery } from '@apollo/client';
import { ProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { ProjectOneServiceSettingsPostProcessing } from 'components/Projects/Settings/ProjectService/PostProcessingTab';
import { ProjectOneServiceCustomYAML } from 'components/Projects/Settings/ProjectService/ProjectOneServiceCustomYAML';
import { ProjectOneServiceSettingsFormRoutes } from 'components/Projects/Settings/ProjectService/ProjectOneServiceSettingsFormRoutes';
import { ProjectOneServiceVariables } from 'components/Projects/Settings/ProjectService/ProjectOneServiceVariables';
import { useApiQuery, useUser } from 'utils/common';
import { iDeployment, iDeploymentServicesConfig, iProjectModel } from 'shared/deployment';
import { ProjectOneServiceSettingsHelmTab } from 'components/Projects/Settings/ProjectService/HelmTab';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { DockerWizard } from 'components/Projects/YamlEditor/wizard/DockerWizard';
import { PROJECT_CODE_NAME } from 'interface/common';
import { YamlEditor } from 'components/Projects/YamlEditor/YamlEditor';
import { StarFilled } from '@ant-design/icons';
import { useGqlAllServicesConfigurationUI, useGqlDeploymentHasMonitoring } from 'services/deployment.service';
import { DeploymentOneServiceSettings } from 'components/Deployments/Setting/General/DeploymentOneServiceSettings';
import { isInAdvancedMode, setAdvancedMode } from 'utils/util';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { OneServiceMetrics } from './metrics/OneServiceMetrics';
import { ProjectOneServiceSettingsFormMonitor } from 'components/Projects/Settings/ProjectService/ProjectOneServiceSettingsFormMonitor';

const { Provider } = ProjectDeploymentSyncVarsContext;

/** http://localhost:3000/#/app/43/configuration/services
 * @param props
 * @returns */
export const ConfigurationServices = (props: { deployment: iDeployment; serviceName: string }): ReactElement => {
  const getActiveTabs = (url: string) => {
    const parts: string[] = url.split('/');
    const lastValue: string = parts[parts.length - 1];
    return lastValue;
  };

  const history = useHistory();
  const deployment = props.deployment;
  const project: iProjectModel = deployment?.ProjectModel;
  const advancedMode = localStorage.getItem('AdvancedMode-dp-' + deployment.id);
  const params: any = useParams();
  const { activeTabType } = params;
  const [isAdvancedMode, setIsAdvancedMode] = useState(isInAdvancedMode(`dp`, deployment.id));
  const url: string = window.location.hash;

  const [current, setCurrent] = useState(getActiveTabs(url));
  if (current == 'custom-yaml' || current == 'post-processing-rules') {
    localStorage.setItem('AdvancedMode-dp-' + deployment.id, 'true');
  }

  useEffect(() => {
    setCurrent(getActiveTabs(url));
  }, [url]);
  const user = useUser();

  const deploymentId = deployment?.id;
  const [getSyncVars, { loading, error, data }] = useLazyQuery(
    gql`
      query DeploymentsController_debugUI_deploymentSyncVars($deploymentId: Int!) {
        DeploymentsController_debugUI_deploymentSyncVars(deploymentId: $deploymentId) {
          syncVars
        }
      }
    `,
  );

  const hasMonitoring = useGqlDeploymentHasMonitoring(props.deployment.id, props.serviceName);

  const qlQuery = useGqlAllServicesConfigurationUI(Number(deployment.id));
  const servicesConfig: iDeploymentServicesConfig[] = qlQuery.data?.DeploymentsController_AllServicesConfigurationUI || [];

  const serviceConfig = servicesConfig.find(service => service.serviceName === props.serviceName);

  const [serviceData, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(project?.id, props.serviceName),
    [project?.id, props.serviceName],
  );
  const service: iCloudProjectServiceYamlSpecs = serviceData;

  useEffect(() => {
    if (deploymentId) getSyncVars({ variables: { deploymentId: deploymentId } });
  }, [deploymentId]);

  if (loading || serviceLoader || qlQuery.loading) {
    return <Skeleton active={true} loading={true} />;
  }
  if (props.serviceName === 'new') {
    return <NewService deployment={deployment} />;
  }

  const uiType = service?.uiType || null;
  let defaultOpenKey = 'metrics';
  if (!hasMonitoring) {
    defaultOpenKey = 'favorit';
  }
  if (deployment.isReady !== true) {
    if (serviceConfig) {
      defaultOpenKey = 'favorit';
    } else if (uiType === `helm`) {
      defaultOpenKey = 'helm';
    } else if (uiType === `docker`) {
      defaultOpenKey = 'deployment-configuration';
    } else if (uiType === `k8s`) {
      defaultOpenKey = 'custom-yaml';
    }
  }
  if (url.split('/').length == 6) {
    history.push(`/app/${props.deployment.id}/configuration/services/${props.serviceName}/${defaultOpenKey}`);
  }

  const selectedTabType = activeTabType || defaultOpenKey;
  const projectFunctions = { service: service, project: project, serviceName: props.serviceName, tabType: selectedTabType };

  if (!deploymentId || !user) {
    return <Skeleton active={true} loading={true} />;
  }

  const handleOnClick = val => {
    setIsAdvancedMode(val);
    setAdvancedMode(`dp`, deployment.id, val);
  };

  const items = [
    deployment.isReady === true && hasMonitoring
      ? {
          label: 'Metrics',
          key: 'metrics',
          uiTypes: [`docker`, `helm`, `k8s`, null],
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forAdvancedMode: false,
        }
      : null,
    {
      label: 'Favorite settings',
      key: 'favorit',
      icon: <StarFilled />,
      forAdvancedMode: false,
      permission: userHasPermission(user, UserPermissions.DeploymentMange),
    },
    {
      label: 'Wizard',
      key: 'deployment-configuration',
      uiTypes: [`docker`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
    },
    {
      label: 'YAML advanced configuration',
      key: 'deployment-yaml',
      uiTypes: [`docker`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
    },
    {
      label: 'Helm',
      key: 'helm',
      uiTypes: [`helm`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
    },
    {
      label: 'Variables',
      key: 'variables',
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
    },
    {
      label: 'Routes',
      key: 'route',
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
    },
    {
      label: 'Monitoring',
      key: 'monitoring',
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: false,
    },
    {
      label: 'Post Processing',
      key: 'post-processing-rules',
      uiTypes: [`helm`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: true,
    },
    {
      label: 'Custom YAML Template',
      key: 'custom-yaml',
      uiTypes: [`helm`, `k8s`, null],
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forAdvancedMode: uiType !== `k8s`,
    },
  ];

  const gitTabsFiltered = items
    .filter(Boolean)
    .filter(v => !v.uiTypes || v.uiTypes.includes(uiType))
    .filter(v => v.permission !== false)
    .filter(v => {
      const res = v.forAdvancedMode === isAdvancedMode || v.forAdvancedMode === false;
      return res;
    });

  const onClick: MenuProps['onClick'] = e => {
    setCurrent(e.key);
    history.push(`/app/${deploymentId}/configuration/services/${props.serviceName}/${e.key}`);
  };

  let result = <DeploymentOneServiceSettings serviceName={props.serviceName} deployment={deployment} schema={serviceConfig} />;
  // if (current == 'favorit') {
  //   result = <DeploymentOneServiceSettings serviceName={props.serviceName} deployment={deployment} schema={serviceConfig} />;
  // }
  if (current == 'metrics') {
    result = <OneServiceMetrics service={service} serviceName={props.serviceName} deployment={deployment} />;
  }

  if (current == 'variables') {
    result = <ProjectOneServiceVariables deployment={deployment} {...projectFunctions} />;
  }
  if (current == 'deployment-configuration') {
    result = (
      <DockerWizard
        key="wizard"
        serviceName={props.serviceName}
        fileName={`${PROJECT_CODE_NAME}/services/${props.serviceName}/template.yaml`}
        project={project}
        deployment={deployment}
      />
    );
  }
  if (current == 'deployment-yaml') {
    result = <YamlEditor key={`yaml-editor`} fileName={`${PROJECT_CODE_NAME}/services/${props.serviceName}/template.yaml`} deployment={deployment} />;
  }
  if (current == 'helm') {
    result = <ProjectOneServiceSettingsHelmTab {...projectFunctions} deployment={deployment} />;
  }
  if (current == 'route') {
    result = <ProjectOneServiceSettingsFormRoutes deployment={deployment} {...projectFunctions} />;
  }
  if (current == 'post-processing-rules') {
    result = <ProjectOneServiceSettingsPostProcessing deployment={deployment} {...projectFunctions} />;
  }
  if (current == 'custom-yaml') {
    result = <ProjectOneServiceCustomYAML deployment={deployment} {...projectFunctions} />;
  }
  if (current == 'monitoring') {
    result = <ProjectOneServiceSettingsFormMonitor deployment={deployment} {...projectFunctions} />;
  }

  return (
    <Provider value={{ serviceName: props.serviceName, syncVars: data?.DeploymentsController_debugUI_deploymentSyncVars?.syncVars }}>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={gitTabsFiltered}
        style={{ width: '90%', float: 'left', backgroundColor: 'initial' }}
      />
      <Switch
        checkedChildren="Advanced Mode"
        unCheckedChildren="Basic mode"
        onClick={handleOnClick}
        checked={isAdvancedMode}
        style={{ float: 'right' }}
      />
      <div style={{ marginTop: '65px' }}>{result}</div>
    </Provider>
  );
};
