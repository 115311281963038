import gql from 'graphql-tag';
import { Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useGqlDeploymentById, useGqlDeploymentHasWebRoutes } from '../../../../services/deployment.service';
import { layout, updateDeployment } from '../common';
import { iDeployment } from 'shared/deployment';
import { useAuthedQuery } from 'utils/qlAuth';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Form, Button, Input, Skeleton, Select, Tag, Typography, notification } from 'antd';
import { buttonBorder, buttonWidth } from 'utils/styles';
import { LinkOwnDomain } from '../help/LinkOwnDomain';

const { Item } = Form;
const { Option } = Select;

export default function DeploymentGeneralSettingsForm({ deploymentId }: any) {
  const [domain, setDomain] = useState(null);
  const dpQuery = useGqlDeploymentById(deploymentId);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;
  const history = useHistory();

  const hasRoutes = useGqlDeploymentHasWebRoutes(deploymentId);
  const qTagsList = useAuthedQuery(
    gql`
      query TagsController_tagsList {
        TagsController_tagsList {
          id
          name
          color
        }
      }
    `,
    {},
  );
  const tags = qTagsList?.data?.TagsController_tagsList || [];

  useEffect(() => {
    deployment ? setDomain(deployment.domain) : null;
  }, [domain, deployment]);

  if (!dpQuery.loading && !dpQuery.data?.DeploymentsController_getDeployment) {
    debugger;
    notification.error({ message: 'Deployment not found' });
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }

  if (error || loading) {
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <>
      <Form onFinish={values => updateDeployment(deployment.id, values)}>
        <Item
          name="name"
          label={`Name ${'\u00A0'.repeat(25)}`}
          initialValue={deployment.name}
          rules={[{ required: true, message: 'Please input your Application Name Here!' }]}
        >
          <Input placeholder="Enter Application Name Here" />
        </Item>

        <Item hidden={!hasRoutes} name="domain" label={`URL ${'\u00A0'.repeat(32)}`} initialValue={deployment.domain}>
          <Input
            placeholder="Enter application domain"
            onChange={e => setDomain(e.target.value)}
            addonAfter={<LinkOwnDomain region={deployment.RegionModel} />}
          />
        </Item>

        {tags?.length && (
          <Item name="tags" label={`Tags ${'\u00A0'.repeat(31)}`} initialValue={deployment.tags}>
            <Select mode="multiple" placeholder="Select Tags Here">
              {tags.map(
                (elem: {
                  id: Key;
                  color: string | (string & {});
                  name: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
                }) => (
                  <Option value={elem.id} key={elem.id}>
                    <Tag color={elem.color}> {elem.name} </Tag>
                  </Option>
                ),
              )}
            </Select>
          </Item>
        )}

        <Item>
          <BottomButtons>
            <Button type="primary" htmlType="submit" style={buttonBorder}>
              Save
            </Button>
          </BottomButtons>
        </Item>
      </Form>
    </>
  );
}
