import { Link } from 'react-router-dom';
import { TipBottom } from '../Tooltip/Tooltip';
import { Space, Card, Image, Typography, Flex } from 'antd';

interface iBigOption {
  customUI?: any;
  name?: string | any;
  secondary?: string | any;
  path?: string;
  onClick?: () => void;
  tip?: string;
  img?: string;
}

interface iBigOptions {
  options: iBigOption[];
}

interface iClick {
  stopPropagation: () => any;
  preventDefault: () => any;
}

const { Title, Text } = Typography;

export const Center = ({ children }) => <Flex justify="center"> {children} </Flex>;

export const BigOption = (props: iBigOption) => {
  const option = props;
  const { customUI: ui, tip, path, name, secondary } = option;

  const handleOnClick = (e: iClick) => (option.onClick ? (e.stopPropagation(), option.onClick()) : !option?.path && e.preventDefault());

  if (ui) {
    return ui;
  }
  return (
    <TipBottom tip={tip}>
      <Link to={path} onClick={handleOnClick}>
        <Card size="small" bordered={false}>
          <Center>
            <Image src={'branding/newCluster.svg'} preview={false} height={30} />
          </Center>
          <Center>
            <Title level={5}> {name} </Title>
          </Center>
          <Center>
            <Text type="secondary"> {secondary} </Text>
          </Center>
        </Card>
      </Link>
    </TipBottom>
  );
};

export const BigOptions = (props: iBigOptions) => (
  <Space direction="horizontal" style={{ width: '100%' }}>
    {props.options.map((option, index) => (
      <BigOption key={index} {...option} />
    ))}
  </Space>
);

export const CreateCard = (props: { children: any; title?: string }) => (
  <Center data-qa="CreateCard">
    <Space direction="vertical">
      <Text /> <Text />
      <Center>
        <Title level={3}> {props.title || `Ready to Get Started?`} </Title>
      </Center>
      <Center>
        <Text type="secondary"> {props.children} </Text>
      </Center>
      <Text /> <Text />
    </Space>
  </Center>
);

export const InfoCluster = () => <CreateCard data-qa="InfoCluster"> Please choose where you want your application to run. </CreateCard>;
export const InfoApplication = () => <CreateCard data-qa="InfoApplication"> Please choose how you want to deploy your application. </CreateCard>;
