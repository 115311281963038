import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Select, Space, Tabs, Tag } from 'antd';
import { JsonFormModalUI } from './JsonFormModalUI';
import { useContext } from 'react';
import { ProjectDeploymentSyncVarsContext } from '../ProjectDeploymentContext';
import { CommonFormUI, CommonUI_DeploymentVarWidget, CommonUI_text } from './CommonFormUI';
import { form_schema_livenessProbe, form_schema_ui_livenessProbe } from './schema/ContainerLivenessProbe';
import { form_env_schema, form_env_schema_ui } from './schema/ContainerEnv';
import { form_ports_schema, form_ports_schema_ui } from './schema/ContainerPorts';
import { containerVolumeMountsDynamicSchema } from './schema/ContainerVolumeMounts';

export interface iContainersUIProps {
  yamlObj: any;
  onChange: (key: string, newObj: any, action: string) => void;
}

export const ContainersUI = (props: iContainersUIProps) => {
  const tabsArr = [];
  let initContainers = props.yamlObj?.spec?.template?.spec?.initContainers;
  if (Array.isArray(initContainers) === false) {
    initContainers = [];
  }
  initContainers.forEach((current_container, i) => {
    tabsArr.push({
      label: `[init] ${current_container.name || 'container ' + i}`,
      key: `init-container-${i}`,
      children: (
        <OneContainersUIForm
          type={`initContainers`}
          path={`.spec.template.spec.initContainers`}
          indexInArray={i}
          container={current_container}
          onChange={props.onChange}
          deploymentYaml={props.yamlObj}
        />
      ),
    });
  });
  let containers = props.yamlObj?.spec?.template?.spec?.containers;
  if (Array.isArray(containers) === false) {
    containers = [];
  }
  containers.forEach((current_container, i) => {
    tabsArr.push({
      label: `${current_container.name || 'container ' + i}`,
      key: `container-${i}`,
      children: (
        <OneContainersUIForm
          type={`containers`}
          path={`.spec.template.spec.containers`}
          indexInArray={i}
          container={current_container}
          onChange={props.onChange}
          deploymentYaml={props.yamlObj}
        />
      ),
    });
  });

  tabsArr.push({
    label: (
      <>
        New <PlusCircleOutlined />
      </>
    ),
    key: `container-new`,
    children: <NewContainersUIBtn onChange={props.onChange} />,
  });

  return <Tabs tabPosition={'top'} defaultActiveKey="1" items={tabsArr} />;
};

const NewContainersUIBtn = (props: { onChange: any }) => {
  return (
    <Space direction="vertical">
      <Button
        onClick={() => {
          props.onChange(
            `.spec.template.spec.containers`,
            { name: `new`, image: `strm/helloworld-http:latest`, ports: [{ containerPort: 80 }] },
            `push`,
          );
        }}
      >
        Create new container
      </Button>
      <Button
        onClick={() => {
          props.onChange(`.spec.template.spec.initContainers`, { name: `init`, image: `strm/helloworld-http:latest` }, `push`);
        }}
      >
        Create new init container
      </Button>
    </Space>
  );
};

const OneContainersUIForm = (props: { deploymentYaml: any; indexInArray: number; type: string; onChange: any; container: any; path: string }) => {
  const syncVarsContext = useContext(ProjectDeploymentSyncVarsContext);

  const path = `${props.path}[${props.indexInArray}]`;
  const form = [
    {
      name: 'Name',
      children: <CommonUI_text value={props.container.name} onChange={props.onChange} path={path} propertyName={`name`} />,
    },
    {
      name: 'Image',
      children: <CommonUI_DeploymentVarWidget value={props.container.image} onChange={props.onChange} path={path} propertyName={`image`} />,
    },
    {
      name: 'Liveness probe',
      children: (
        <Space>
          <Button
            onClick={() => {
              JsonFormModalUI({
                formData: { ports: props?.container?.livenessProbe || {} },
                formConfigs: { schema: form_schema_livenessProbe, ui: form_schema_ui_livenessProbe },
                SyncVarsContext: syncVarsContext,
                title: `Liveness probe`,
                onChange: (v: any) => {
                  props.onChange(`${props.path}[${props.indexInArray}].livenessProbe`, v);
                },
              });
            }}
          >
            <EditOutlined />
          </Button>
          {props?.container?.livenessProbe ? (
            <>
              <Tag color="green">Configured</Tag>
            </>
          ) : (
            <Tag>Not configured</Tag>
          )}
        </Space>
      ),
    },
    {
      name: 'Restart policy',
      children: (
        <Select
          style={{ minWidth: '200px' }}
          value={props.container.restartPolicy || 'Always'}
          onChange={v => props.onChange(`${props.path}[${props.indexInArray}].restartPolicy`, v)}
        >
          <Select.Option value="Always">Always</Select.Option>
          <Select.Option value="OnFailure">OnFailure</Select.Option>
          <Select.Option value="Never">Never</Select.Option>
        </Select>
      ),
    },
    {
      name: 'Image pull policy',
      children: (
        <Select
          style={{ minWidth: '200px' }}
          value={props.container.imagePullPolicy || 'Always'}
          onChange={v => props.onChange(`${props.path}[${props.indexInArray}].imagePullPolicy`, v)}
        >
          <Select.Option value="IfNotPresent">IfNotPresent</Select.Option>
          <Select.Option value="Always">Always</Select.Option>
          <Select.Option value="Never">Never</Select.Option>
        </Select>
      ),
    },
    {
      name: 'Environment variables',
      children: (
        <Space>
          <Button
            onClick={() => {
              JsonFormModalUI({
                formData: props?.container?.env || [],
                formConfigs: { schema: form_env_schema, ui: form_env_schema_ui },
                SyncVarsContext: syncVarsContext,
                onChange: (v: any) => {
                  props.onChange(`${props.path}[${props.indexInArray}].env`, v);
                },
              });
            }}
          >
            <EditOutlined />
          </Button>
          {(props?.container?.env || []).length ? <Tag color="green">{props?.container?.env?.length} env vars</Tag> : <Tag>Not configured</Tag>}
        </Space>
      ),
    },
    {
      name: 'Ports',
      children: (
        <Space>
          <Button
            onClick={() => {
              JsonFormModalUI({
                title: `Volume mounts configuration`,
                formData: props?.container?.ports || [],
                formConfigs: { schema: form_ports_schema, ui: form_ports_schema_ui },
                SyncVarsContext: syncVarsContext,
                onChange: (v: any) => {
                  props.onChange(`${props.path}[${props.indexInArray}].ports`, v);
                },
              });
            }}
          >
            <EditOutlined />
          </Button>

          {props?.container?.ports ? (
            <>
              <Tag color="green">Configured</Tag>
            </>
          ) : (
            <Tag>Not configured</Tag>
          )}
        </Space>
      ),
    },
    {
      name: 'Volume mounts',
      children: (
        <Space>
          <Button
            onClick={() => {
              if (!props.deploymentYaml?.spec?.template?.spec?.volumes?.length) {
                Modal.error({
                  title: `No volumes configured`,
                  content: `Please configure volumes first on the Volumes tab.`,
                });
                return;
              }

              JsonFormModalUI({
                formData: props?.container?.volumeMounts || [],
                formConfigs: containerVolumeMountsDynamicSchema(props.deploymentYaml, props.container),
                SyncVarsContext: syncVarsContext,
                onChange: (v: any) => {
                  props.onChange(`${props.path}[${props.indexInArray}].volumeMounts`, v);
                },
              });
            }}
          >
            <EditOutlined />
          </Button>

          {props?.container?.volumeMounts?.length ? (
            <>
              <Tag color="green">{props?.container?.volumeMounts?.length} volume</Tag>
            </>
          ) : (
            <Tag>Not configured</Tag>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <CommonFormUI form={form} />

      <Button
        type="dashed"
        danger
        onClick={() => {
          props.onChange(props.path, props.indexInArray, `splice`);
        }}
      >
        Delete
      </Button>
    </>
  );
};
