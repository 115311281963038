import { ReactElement, useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { notification, Skeleton } from 'antd';
import { TitleUIRow } from 'layout/TitleUI';
import { ProjectDeploymentContext } from 'components/Projects/ProjectDeploymentContext';
import { ProjectWidgetsRow } from 'components/Projects/ProjectWidgetsRow';
import { TemplateSettingsRoutes } from './TemplateSettingsRoutes';
import { useGqlDeploymentById } from 'services/deployment.service';
import { iProjectModel } from 'shared/deployment';

/**
 * ProjectSetting tabs component
 * @returns
 */

const { Provider } = ProjectDeploymentContext;

export const Configuration = (): ReactElement => {
  // @ts-ignore
  const params: any = useParams();
  const { deploymentId, tabName, serviceName } = params;
  const [deploymentContext, setDeploymentContext] = useState({ deployment: null });
  const history = useHistory();

  const qlDp = useGqlDeploymentById(Number(deploymentId));
  const project: iProjectModel = qlDp.data?.DeploymentsController_getDeployment?.ProjectModel;

  useEffect(() => {
    return qlDp.stopPolling;
  }, []);

  useEffect(() => {
    if (qlDp.data?.DeploymentsController_getDeployment) {
      setDeploymentContext({ deployment: qlDp.data?.DeploymentsController_getDeployment });
    }
  }, [qlDp.data?.DeploymentsController_getDeployment]);

  if (!qlDp.loading && !qlDp.data?.DeploymentsController_getDeployment) {
    notification.error({ message: 'Deployment not found' });
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }

  if (qlDp.loading || !project) {
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <Provider value={deploymentContext}>
      <TitleUIRow title={`Template: ${project?.title || project?.name}`} />
      <ProjectWidgetsRow project={project} deployment={qlDp.data?.DeploymentsController_getDeployment} setDeploymentContext={setDeploymentContext} />
      <TemplateSettingsRoutes
        tabName={tabName}
        serviceName={serviceName}
        project={project}
        deployment={qlDp.data?.DeploymentsController_getDeployment}
      />
    </Provider>
  );
};
