import { Console } from 'console-feed';
import { Message } from 'console-feed/lib/definitions/Console';
import { logsText } from 'utils/styles';
import { getThemeName } from 'utils/theme';

interface iUnifieConsoleProps {
  logs: any;
  extraStyles?: React.CSSProperties;
}

export function UnifieConsole({ logs, extraStyles }: iUnifieConsoleProps) {
  return <Console logs={logs} variant={getThemeName()} styles={{ ...logsText, ...extraStyles }} />;
}
