// REPEATED STYLES
export const buttonSize: any = { minWidth: 185 };
export const buttonBorder: any = { border: '1px solid transparent' };
export const absolutely: any = { position: 'absolute' };
export const relatively: any = { position: 'relative' };
export const fixedly: any = { position: 'fixed' };
export const primaryBorderColor: any = { backgroundColor: '#FFFFFF' };
export const themesCustom: any = { width: 220 };
export const textCenter: any = { textAlign: 'center' };
export const clusterTags: any = { width: 293 };
export const blackText: any = { color: '#000000' };
export const boldText: any = { fontWeight: 'bold' };
export const redColor: any = { color: 'red' };
export const greenColor: any = { color: 'green' };
export const blueColor: any = { color: 'blue' };
export const orangeColor: any = { color: 'orange' };
export const dotTwo: any = { ...boldText, ...redColor, paddingLeft: '2px' };
export const rightPosition: any = { right: 10 };
export const autoOverflow: any = { overflow: 'auto' };
export const spaceWidth: any = { width: '100%' };
export const spaceHeight: any = { height: '100%' };
export const spacedWidth: any = { width: '100vw' };
export const spacedHeight: any = { height: '100vh' };
export const primaryBoxShadow: any = { boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset' };
export const primaryBorder: any = { borderRadius: '0px' };
export const primaryBordered: any = { border: '1px solid #BAC7D5' };
export const floatingRight: any = { float: 'right' };
export const whiteBackground: any = { background: '#FFFFFF' };
export const marginLeftStyle: any = { marginLeft: '40px' };
export const fontSizeSmall: any = { fontSize: '12px' };
export const initialTop: any = { top: 0 };
export const borderSec: any = { borderRadius: '2px' };
export const flexDisplay: any = { display: 'flex' };
export const centerAlign: any = { alignItems: 'center' };
export const contentJustified: React.CSSProperties = { justifyContent: 'space-between' };
export const contentCentered: React.CSSProperties = { alignItems: 'center' };
export const contentJustifiedAndCentered: React.CSSProperties = { justifyContent: 'space-between', alignItems: 'center' };
export const boxPaddingAndBorderRadius: React.CSSProperties = { padding: '8px', borderRadius: '4px' };
export const baselineAlign: any = { alignItems: 'baseline' };
export const leftMargin: any = { marginLeft: '20px' };
export const topsMargin = { marginTop: '20px' };
export const bottomMargin = { marginBottom: '20px' };
export const bottomedMargin = { marginBottom: '80px' };
export const bottomPadding = { paddingBottom: '20px' };
export const topPadding = { paddingTop: '20px' };
export const forthWidth = { width: '400px' };
export const skeletonPadding = { padding: '20px' };

// COMMON STYLES
export const dividerColor: any = { backgroundColor: 'black' };
export const noResultMargin: any = { marginTop: -70 };
export const dropdownWidth: any = { width: 500 };
export const imageStyle: any = { height: '20px', width: '30px' };
export const shadowStyle: any = { filter: 'drop-shadow(0px 0px 0.1px #FFFFFF)' };
export const fieldWidth: any = { width: '18vw' };
export const dockerWidth: any = { width: '80vw' };
export const dockerInput: any = { width: '12vw' };
export const filterWidth: any = { width: 155 };
export const userWidth: any = { width: 120 };
export const cardWidth: any = { width: 300 };
export const paddingStyle: any = { paddingLeft: 5 };
export const breadcrumbColor: any = { color: '#00A58E' };
export const checkboxStyle: any = { ...marginLeftStyle, marginBottom: '40px' };
export const deploymentCardHeight: any = { height: 145 };
export const projectCardHeight: any = { height: 82.5 };
export const integrationCardHeight: any = { height: 88.5 };
export const logoAvatar: any = { backgroundColor: '#F56A00' };
export const dropdownStyle: any = { height: '40px', ...forthWidth };
export const tags: any = { width: 80 };
export const postContent: any = { margin: '5px' };
export const postPop = { color: '#0000FF' };
export const AddIcoStyle: any = { ...fontSizeSmall, color: '#B7B6B6', paddingLeft: '6px', marginTop: '6px' };
export const clusterHeight: any = { marginBottom: '24px', maxHeight: '70vh' };
export const searchNoResult: any = { marginLeft: 8 };
export const filterColor: any = { color: '#BDBDBD' };
export const noWrap: any = { whiteSpace: 'nowrap' };
export const topMargin: any = { marginTop: -30 };
export const modalPosition: any = { right: -200, top: 130 };
export const tabMargin: any = { marginBottom: 24 };
export const topMargined: any = { marginTop: '-15px' };
export const logsText: any = { BASE_FONT_SIZE: '12px', ARROW_FONT_SIZE: '12px' };
export const bottomButtonShadow: any = { boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px inset' };
export const hideScrollbar: any = {
  // Please - not hide scrollbar
  /* msOverflowStyle: 'none', scrollbarWidth: 'none' */
};
export const themeCard: any = { ...whiteBackground, boxShadow: '0 4px 30px #D3D3D3', border: '1px solid #FFFFFF' };
export const buttonWidth: any = { ...buttonSize, ...buttonBorder };
export const buttonWidthNew: any = { minWidth: 310, ...buttonBorder };
export const divWidth: any = { ...absolutely, ...initialTop, width: '70vw', right: 5 };
export const innerSidebar: any = { position: 'relative', ...primaryBorderColor, left: -15, minHeight: 'calc(100vh - 115px)' };
export const innerSidebarButton: any = { ...fixedly, ...themesCustom, bottom: 19, marginLeft: 15 };
export const innerContentCluster: any = { ...relatively, width: '1100px' };
export const internalStatusTag: any = { ...textCenter, width: 100 };
export const userTags: any = { ...textCenter, ...tags };
export const postLeft: any = { ...fixedly, top: 120, left: 465 };
export const minusKey: any = { ...dotTwo, ...absolutely, marginLeft: '-15px' };
export const clusterEndTag: any = { ...absolutely, ...rightPosition, top: 15 };
export const newServices: any = { ...relatively, top: 45, left: 15 };
export const menuButton: any = { ...fixedly, ...whiteBackground, bottom: '0px', width: '250px', height: '73px', borderTop: '1px solid #CCCCCCD4' };
export const switchMargin: any = { marginRight: 10, marginTop: 5 };
export const templateMargin: any = { height: '180px', marginBottom: '10px' };
export const selectWidth: any = { minWidth: '200px' };
export const logsStyle: any = {
  ...hideScrollbar,
  ...buttonBorder,
  ...autoOverflow,
  ...spaceHeight,
  minHeight: '250px',
  maxHeight: '500px',
  marginBottom: '20px',
};
export const nav1: any = { border: '1px #0000002E solid', padding: '3px', ...borderSec };
export const nav2: any = {
  ...floatingRight,
  ...borderSec,
  padding: '4px',
  background: '#0000000A',
  marginTop: '-3px',
  marginRight: '-3px',
  paddingLeft: '11px',
  paddingRight: '11px',
  borderLeft: '1px solid #00000026',
};
export const nav3: any = { ...flexDisplay, margin: '15px 0' };
export const nav4: any = { marginRight: '5px' };
export const nav5: any = { width: '50%' };
export const gitButtonModalStyle: any = {
  border: '1px solid #0000003B',
  padding: '5px',
  borderRadius: '5px',
  backgroundColor: '#CCCCCC17',
  width: '640px',
  height: '240px',
};
export const modalStyle: any = { ...autoOverflow, ...gitButtonModalStyle, wordWrap: 'break-word' };
export const bannerStyle: any = { backgroundColor: 'transparent', border: '1px solid transparent' };
export const extraButton: any = { ...fixedly, right: 10, bottom: 20 };
export const revisionWidth: any = { width: 200 };
export const imageMarginTop: any = { marginTop: '6px' };
export const imageMarginBottom: any = { marginBottom: '12px' };
export const deleteButton: any = { marginTop: 15 };
export const growNorShrink: React.CSSProperties = { flex: '1 1 auto' };
export const flexLayoutGrowLeft: React.CSSProperties = { ...growNorShrink, maxWidth: '50%', minWidth: '15%' };

// ALL SIGNIN PAGES
export const cardBlurShadow: any = { boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' };
export const cardBlurAlign: any = { marginTop: -10 };
export const formInput: any = { width: 350 };
export const formButton: any = { width: 170 };
export const cardBlurContentButton: any = { backgroundColor: '#BFE8E2', border: '1px solid #BFE8E2', borderRadius: '15px' };
export const cardTextStyle: any = { color: '#FFFFFF' };
export const mailSent: any = { width: 370 };
export const textJustify: any = { textAlign: 'justify' };
export const cardStyle: any = { ...buttonBorder, background: 'transparent' };
export const cardForm: any = { ...absolutely, top: '10%', left: '5%', width: '90%' };
export const cardFormContent: any = { ...buttonBorder, width: 450 };
export const cardAlign: any = { ...absolutely, ...buttonBorder, top: '15%', left: '5%', width: 370 };
export const cardBlur: any = { ...spaceWidth, ...absolutely, bottom: 10, zIndex: -10 };
export const resetText: any = { ...mailSent, ...textJustify };
export const cardBlurContent: any = {
  ...cardStyle,
  ...cardBlurShadow,
  ...buttonBorder,
  width: 720,
  backdropFilter: 'blur(50px)',
  backgroundColor: 'transparent',
  right: 10,
  bottom: 10,
};
export const authImages: any = {
  zIndex: -20,
  position: 'fixed',
  top: 0,
  right: 0,
  height: '100vh',
  width: '620px',
  maxWidth: '620px',
  minWidth: '50vw',
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

// ADMIN LAYOUT
export const contentStyle: any = { margin: '0 16px', paddingTop: '16px', paddingBottom: '100px' };
export const floatingLeft: any = { float: 'left' };
export const layoutStyle: any = { height: 'calc(100vh - 81px)' };
export const widgetStyle: any = { marginTop: -20, marginLeft: -20 };
export const divStyle: any = { ...spaceWidth, height: 100 };
export const footerStyle: any = { ...textCenter, color: '#CCCCCC' };
export const siderStyle: any = { ...spaceHeight, ...primaryBoxShadow };
export const avatarShadow: any = { boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px' };
export const containerStyle: any = { ...hideScrollbar, ...spaceWidth, ...autoOverflow, ...spaceHeight, ...primaryBoxShadow };
export const headerStyle: any = {
  ...primaryBorder,
  ...cardBlurAlign,
  height: '45px',
  boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
};
export const infoStyle: any = {
  ...relatively,
  ...primaryBorder,
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px inset',
  paddingTop: '18px',
  paddingLeft: '30px',
  paddingRight: '0px',
  height: '78px',
};
export const infobarStyle: any = { ...spaceWidth, ...buttonBorder, ...infoStyle };
export const newImage: any = { position: 'relative', marginTop: -167 };
export const logoStyle: any = { marginTop: 3 };
export const extraInfo: any = { ...formInput, ...cardStyle, ...textJustify };

// USERS
export const roleTag: any = { ...blackText, ...primaryBordered, ...primaryBorderColor };
export const loginTag: any = { ...blackText, ...primaryBordered, backgroundColor: '#F1F8FE' };
export const externalStatusTag: any = { ...absolutely, ...rightPosition, bottom: 15 };
export const idTag: any = { ...absolutely, ...floatingRight, ...rightPosition, top: 10 };

// STATISTICS
export const statisticText = { fontSize: '15px', fontWeight: 800 };

// NO PAGE FOUND
export const noPageFoundStyle = { ...flexDisplay, ...centerAlign, ...spacedHeight, justifyContent: 'center' };

// FULL SCREEN VIEW
export const fullScreenView: any = { ...fixedly, ...initialTop, ...whiteBackground, ...spacedWidth, ...spacedHeight, left: 0, zIndex: 1000 };
export const buttonColor: any = { color: '#00A58E' };

// SERVICES
export const ulStyleA: any = { listStyle: 'none' };
export const ulStyleB: any = { ...ulStyleA, width: '60%' };
export const ulStyleC: any = { ...marginLeftStyle, ...ulStyleA, alignSelf: 'self-start' };
export const liStyleA: any = { ...flexDisplay, ...contentJustified, ...baselineAlign, minWidth: '250px', maxWidth: '300px' };
export const liStyleB: any = { ...flexDisplay, ...centerAlign };
export const labelStyleA: any = { ...boldText, fontSize: '16px' };
export const labelStyleB: any = { ...fontSizeSmall, ...boldText, ...leftMargin };
export const rowStyleA: any = { ...flexDisplay, ...contentJustified, ...baselineAlign };
export const rowStyleB: any = { ...fontSizeSmall, ...boldText, ...leftMargin };
export const formStyleA: any = { ...leftMargin };
export const cardTextStyles: any = { marginRight: '20px' };
export const switchStyleA: any = { marginLeft: '30px' };
export const inputStyleA: any = { marginTop: '10px' };
export const inputStyleB: any = { ...inputStyleA, ...leftMargin };

// STORAGE
export const storageStyle1 = { ...flexDisplay, margin: '5px' };
export const storageStyle2 = { ...flexDisplay, ...centerAlign, width: '300px' };
export const storageStyle3 = { ...forthWidth };

//AUDIT LOGS
export const rowStyleDarkMode: React.CSSProperties = {
  ...cardTextStyle,
  ...contentJustifiedAndCentered,
  backgroundColor: '#3c3f41',
  ...boxPaddingAndBorderRadius,
};

export const rowStyleLightMode: React.CSSProperties = { ...contentJustifiedAndCentered, ...boxPaddingAndBorderRadius };
