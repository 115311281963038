import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { ReactElement, ReactNode, ReactPortal } from 'react';
import { projectService } from 'services/project.service';
import { CustomWidgets } from 'form-components';
import { useAuthedMutation } from 'utils/qlAuth';
import { ProjectController_removeService } from 'queries/queries';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Alert, Button, Col, Popconfirm, Space, Typography, notification } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { buttonBorder, buttonWidth, redColor, spaceWidth } from 'utils/styles';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { useHistory } from 'react-router-dom';
import { useUser } from 'utils/common';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';

export interface iProjectOneServiceSettingsFormProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
  service: iCloudProjectServiceYamlSpecs;
  deployment: iDeployment;
}

interface iVType {
  type: string | number | boolean | ReactElement<string> | Iterable<ReactNode>;
  cmd: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  message: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const oneServiceSettingsForm: any = {
  type: 'object',
  properties: {
    // UIshowRequestsConfig: {
    //   type: 'boolean',
    //   title: 'Show Requests Config In UI',
    //   default: false,
    // },
    enabledByDefault: {
      type: 'boolean',
      title: 'Enabled By Default',
      default: true,
    },
  },
};

const oneServiceSettingsUISchema = {};

const { Text } = Typography;

export const ProjectOneServiceSettingsMainTab = (props: iProjectOneServiceSettingsFormProps) => {
  const { project, serviceName, tabType, service } = props;

  const handleSubmit = async e => {
    const res = await projectService.setProjectServices(project.id, { tabType: tabType, name: e.formData.name, data: e.formData });
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
  };

  const tabGeneral = () => {
    return (
      <Col span={20}>
        <Form
          widgets={CustomWidgets}
          formData={service}
          schema={oneServiceSettingsForm}
          uiSchema={oneServiceSettingsUISchema}
          validator={validator}
          onSubmit={handleSubmit}
          onError={e => console.log('Error in form submit: ', e)}
        >
          <BottomButtons>
            <Space direction="horizontal">
              <Button type="primary" htmlType="submit" style={buttonBorder}>
                Save
              </Button>
              <DeleteServiceBtn serviceName={serviceName} projectId={project.id} deploymentId={props.deployment.id} />
            </Space>
          </BottomButtons>
        </Form>
      </Col>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {tabGeneral()}
    </Space>
  );
};

export const DeleteServiceBtn = (props: { serviceName: string; projectId: number; deploymentId: number }) => {
  const [removeServiceFunction, removeServiceRes] = useAuthedMutation(ProjectController_removeService);
  const history = useHistory();
  const user = useUser();

  if (!userHasPermission(user, UserPermissions.ProjectsMange)) {
    return null;
  }

  return (
    <Popconfirm
      title="Are you sure?"
      placement="topLeft"
      icon={<WarningFilled style={redColor} />}
      okText="Continue"
      cancelText="Cancel"
      description={
        <Space direction="vertical">
          <Text>
            Do you want to Delete the Service <Text strong> {props.serviceName} </Text> ?
          </Text>
          <Text /> <Text />
          <Alert showIcon type="error" message={`This action is irreversible, and deletion will lead to the loss of data.`} />
        </Space>
      }
      onConfirm={async () => {
        await removeServiceFunction({ variables: { projectId: props.projectId, serviceName: props.serviceName } });
        history.push(`/app/${props?.deploymentId}/configuration/new-service/add`);
        return true;
      }}
    >
      <Button type="primary" danger style={buttonBorder}>
        Delete Service
      </Button>
    </Popconfirm>
  );
};
