import { ArrowLeftOutlined } from '@ant-design/icons';
import { Space, Image, Typography } from 'antd';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { PROJECT_NAME } from 'interface/common';
import { Fragment } from 'react';

const { Title, Text } = Typography;

export const CloudType_GCP = () => (
  <Space direction="vertical">
    <Text>
      <Image src="branding/logoGCP.svg" preview={false} height={30} />
      {'\u00A0'.repeat(3)}
      <TipTop tip="Click here to view Google Cloud Platform Docs">
        <Text strong>
          <a href="https://cloud.google.com/" target="_blank">
            Google Cloud Platform
          </a>
        </Text>
      </TipTop>
    </Text>
  </Space>
);

export const CloudType_AWS = () => {
  const awsLinks = [
    {
      title: 'Click here to view AWS EKS Docs',
      href: 'https://docs.aws.amazon.com/eks/latest/userguide/create-cluster.html',
      text: 'AWS EKS Docs',
    },
    { title: 'Click here to view AWS EKS Panel', href: 'https://console.aws.amazon.com/eks/home#/clusters', text: 'AWS EKS Panel' },
  ];

  return (
    <Space direction="vertical">
      <Text>
        <Image src="branding/logoAWS.svg" preview={false} height={30} />
        {'\u00A0'.repeat(3)}
        <Text strong>
          <a href="https://www.unifie.cloud/doc/docs/Cluster-provisioning/AWS-terraform/" target="_blank">
            How to create AWS EKS cluster?
          </a>
        </Text>
        &nbsp;using&nbsp;
        {awsLinks.map(({ title, href, text }) => (
          <TipTop tip={title} key={href}>
            <Text strong>
              <a href={href} target="_blank">
                {text}
              </a>
            </Text>
          </TipTop>
        ))}
      </Text>
    </Space>
  );
};

export const CloudType_Azure = () => (
  <Space direction="vertical">
    <Text>
      <Image src="branding/logoAzure.svg" preview={false} height={30} />
      {'\u00A0'.repeat(3)}
      <TipTop tip="Click here to view Azure Docs">
        <Text strong>
          <a href="https://azure.microsoft.com/" target="_blank">
            Azure
          </a>
        </Text>
      </TipTop>
    </Text>
  </Space>
);

export const CloudType_k3s = () => {
  const k3sHelp = [
    'K3S - It is good if you only have one small server. It is the cheapest way to get a small kubernetes cluster.',
    'Lightweight Kubernetes. Production ready, easy to install, half the memory, all in a binary less than 100 MB.',
  ];
  const k3sCode = [
    { command: 'curl -sfL https://get.k3s.io | sh -', description: 'K3S installation command' },
    { command: 'kubectl get nodes', description: 'Copy this if you want to test k3s run' },
  ];
  const k3sLink = [
    {
      href: 'https://www.unifie.cloud/doc/docs/Cluster-provisioning/k3s/',
      text: `Try ${PROJECT_NAME} with K3S in 5 minutes on a local machine or small linux server`,
    },
    {
      href: 'https://github.com/k3s-io/k3s?tab=readme-ov-file#quick-start---install-script',
      text: 'K3S Quick Start documentation',
    },
  ];
  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <Image src="branding/logoK3S.svg" preview={false} height={30} />
        {'\u00A0'.repeat(3)}
        <Space direction="vertical">
          {k3sHelp.map(text => (
            <Text key={text}> {text} </Text>
          ))}
        </Space>
      </Space>
      <Text />
      <Text />
      <Title level={4}> How to install? </Title>
      {k3sCode.map(({ command, description }) => (
        <Text key={command}>
          <CodeLine> {command} </CodeLine> &nbsp; <ArrowLeftOutlined /> &nbsp; {description}
        </Text>
      ))}
      <Text />
      <Text> If any issues, please check our blog post. </Text>
      <Text strong>
        {k3sLink.map(({ href, text }) => (
          <Fragment key={href}>
            <a href={href} target="_blank">
              {text}
            </a>
            &nbsp;or&nbsp;
          </Fragment>
        ))}
      </Text>
    </Space>
  );
};

export const CloudType_other = () => (
  <Space direction="vertical">
    <Text>
      <Title level={4}> You can use other Kubernetes providers. </Title>
      <Text> {PROJECT_NAME} uses standard kubernetes API to manage the cluster. No specific requirements for Kubernetes providers. </Text>
      <Text strong> You can run {PROJECT_NAME} in any Kubernetes cloud. </Text>
    </Text>
  </Space>
);
