import VersionHistory from './VersionHistoryComponent';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApiQuery } from '../../../../utils/common';
import { deploymentService } from '../../../../services/deployment.service';
import { versionsService } from '../../../../services/versions.service';
import { DeploymentVersionInfo } from './DeploymentVersionInfo';
import { iDeployment } from 'shared/deployment';
import { Alert, Col, Form, Button, Select, Modal, Skeleton, Row, Input, Space, Typography, Flex } from 'antd';
import { spaceWidth, textCenter } from 'utils/styles';
import { TipRight } from 'components/SharedComponents/Tooltip/Tooltip';
import { DeployButton } from '../new-deployment/DeployPage';

const { Title, Text } = Typography;
const { Item } = Form;
const { Search } = Input;
const { Option } = Select;

const installUpdates = (deployment: iDeployment, version: any) => {
  return new Promise(resolve => {
    const modal = Modal.confirm({
      title: 'Confirm',
      content: (
        <>
          Do you want to update this Application?
          <br />
          <Text strong style={textCenter}>
            New version name "{version?.name}"
          </Text>
          <br />
          <br />
        </>
      ),
      okText: 'Update',
      cancelText: 'Cancel',
      onOk: async () => {
        await deploymentService.updateDeployment(deployment.id, { version: version.id });
        modal.destroy();
        resolve(true);
      },
      onCancel: () => {
        resolve(false);
        modal.destroy();
      },
      footer: [
        <Space direction="horizontal">
          <Button
            key="submit"
            type="primary"
            onClick={async () => {
              await deploymentService.updateDeployment(deployment.id, { version: version.id });
              resolve(true);
              modal.destroy();
            }}
          >
            Save
          </Button>
          <DeployButton
            deployment={deployment}
            beforeDeploy={async () => {
              await deploymentService.updateDeployment(deployment.id, { version: version.id });
              resolve(true);
              modal.destroy();
              return true;
            }}
          />
          <Button
            key="back"
            onClick={() => {
              resolve(false);
              modal.destroy();
            }}
          >
            Cancel
          </Button>
        </Space>,
      ],
    });
  });
};

export const filterVersions = (el: any, substr: any) => {
  if (!substr) return true;
  const filter = substr.toLowerCase();
  return `#${el.id} ${el.channel} ${el.build ? ` v${el.build}` : 'Last'} ${el?.name} ${el?.title}`.toLowerCase().includes(filter);
};

interface DeploymentUpdateVersionFormProps {
  deployment: iDeployment;
}

export default function DeploymentUpdateVersionForm(props: DeploymentUpdateVersionFormProps) {
  const { deployment } = props;
  const [currentVersion, setCurrentVersion] = useState(deployment.version || 0);
  const [filter, setFilter] = useState('');
  const [versions, versionsError, versionsLoading] = useApiQuery(() => versionsService.publicVersions(projectId));
  const history = useHistory();
  const projectId = deployment.projectId;

  if (versionsError) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }

  if (!deployment || versionsLoading) {
    return <Skeleton active={true} loading={true} />;
  }

  if (!versions.length) {
    const alertMessage = (
      <>
        You do not have any available versions for this project.
        <ul>
          <li>
            <a target="_blank" href="https://www.unifie.cloud/doc/docs/User-Guide/CI-CD/">
              Check our docs on how to use versions in your project.
            </a>
          </li>
        </ul>
        <Title level={1}> Possible solutions </Title>
        <ul>
          <li> Check if your CI/CD integration is working correctly. </li>
          <li> Call api for creating new version at least once. </li>
          <li> Check the response for the api call. </li>
          <li> If you get 403 or 401 errors - Check your api key that you use. </li>
          <li> If you get 404 error - Check the project id. </li>
          <li>
            Read How to use&nbsp;
            <a target="_blank" href="https://www.unifie.cloud/doc/docs/User-Guide/CI-CD/">
              CI/CD integration
            </a>
          </li>
        </ul>
      </>
    );
    return (
      <Row>
        <Col span={24}>
          <Alert showIcon type="error" message={alertMessage} />
        </Col>
      </Row>
    );
  }

  const currentVersionObj = versions.find((v: any) => v.id === currentVersion);

  // onSelect dropdown item
  const handleChange = (e: any) => setCurrentVersion(e);

  const alertDescription = (
    <Space direction="vertical">
      <Text>
        The version will be switched to the Version {currentVersionObj?.name}. Your current version is in&nbsp;
        {deployment.VersionModel && deployment.VersionModel?.name}.
      </Text>
      <Text strong> {currentVersionObj?.description} </Text>
    </Space>
  );

  const channelSort = (a: any, b: any) => b.id - a.id;
  const channelFilter = (el: any) => filterVersions(el, filter);
  const channelMap = (v: any) => {
    return (
      <Option value={v.id} key={v.id}>
        <TipRight tip={v.title}>
          <Text>
            #{v.id}
            <Text type="secondary" italic>
              &nbsp;({v.channel})&nbsp;
            </Text>
            {v?.build ? ` v${v?.build}` : 'Last'} - {v?.name}
          </Text>
        </TipRight>
      </Option>
    );
  };
  const channelData = (
    <Form>
      <Row>
        <Col span={10}>
          <Item>
            <Select defaultValue={deployment.version} onChange={handleChange} style={{ width: '32vw' }}>
              {versions.sort(channelSort).filter(channelFilter).map(channelMap)}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item>
            <Search
              placeholder="Filter by Versions"
              enterButton
              onChange={(e: any) => setFilter(e.target.value)}
              value={filter}
              style={{ width: '25vw' }}
            />
          </Item>
        </Col>
        <Col span={2}>
          {deployment.version !== currentVersion && currentVersion ? (
            <Flex justify={'flex-end'}>
              <Button
                type="primary"
                onClick={e => {
                  installUpdates(
                    deployment,
                    versions.find((v: any) => v.id === currentVersion),
                  );
                }}
              >
                Install Update
              </Button>
            </Flex>
          ) : null}
        </Col>
      </Row>
    </Form>
  );

  return (
    <>
      <Space direction="vertical" style={spaceWidth}>
        {channelData}

        {deployment.version !== currentVersion && currentVersionObj && (
          <Alert
            type="warning"
            showIcon
            message="Set new version for application"
            style={{ ...spaceWidth, marginBottom: '24px' }}
            description={alertDescription}
          />
        )}

        <VersionHistory deployment={deployment} versionId={currentVersion} installUpdates={installUpdates} />

        {deployment?.VersionModel?.images && (
          <DeploymentVersionInfo images={deployment.VersionModel.images} alterImages={currentVersionObj?.images} />
        )}
      </Space>
    </>
  );
}
