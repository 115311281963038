import { PlusSquareTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';
import { getThemeName, Theme } from 'utils/theme';

type IconType = 'PlusSquare' | 'QuestionCircle';
type TwoToneColor = string | [string, string];

interface IconColors {
  light: Record<IconType, TwoToneColor>;
  dark: Record<IconType, TwoToneColor>;
}

const ICON_COLORS: IconColors = {
  light: {
    PlusSquare: '#00A58E',
    QuestionCircle: '#00A58E',
  },
  dark: {
    PlusSquare: ['green', '#1f2a37'],
    QuestionCircle: ['green', '#1f2a37'],
  },
};

const getColorProperty = (icon: IconType): TwoToneColor => {
  const theme: Theme = getThemeName();
  return ICON_COLORS[theme][icon];
};

export function UnifiePlusSquareIcon() {
  const color = getColorProperty('PlusSquare');
  return <PlusSquareTwoTone twoToneColor={color} />;
}

export function UnifieQuestionCircleIcon() {
  const color = getColorProperty('QuestionCircle');
  return <QuestionCircleTwoTone twoToneColor={color} />;
}
