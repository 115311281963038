import { ChartDashboardMap, iDashboardOneChart } from 'components/ChartRenderer/ChartDashboard';
import { useGqlDeploymentHasMonitoring } from 'services/deployment.service';
import { iDeployment } from 'shared/deployment';

export const MonitoringOfServices = (props: { deployment: iDeployment }) => {
  const hasMonitoring = useGqlDeploymentHasMonitoring(props.deployment.id);

  const id = props.deployment.id;
  const OverView_usage: iDashboardOneChart[] = [
    {
      id: 0,
      span: `12`,
      name: 'CPU usage avg',
      height: 400,
      vizState: {
        chartType: 'line',
        query: {
          measures: ['PodsMonitoring.cpuUsageAverage'],
          timeDimensions: [{ dimension: 'PodsMonitoring.createdAt', granularity: `minute`, dateRange: `Last 15 minute` }],
          dimensions: ['PodsMonitoring.uiName'],
          filters: [{ member: 'PodsMonitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
      },
    },
    {
      id: 0,
      span: `12`,
      name: 'Mem usage avg',
      height: 400,
      vizState: {
        chartType: 'line',
        query: {
          measures: ['PodsMonitoring.memUsageAverage'],
          timeDimensions: [{ dimension: 'PodsMonitoring.createdAt', granularity: `minute`, dateRange: `Last 15 minute` }],
          dimensions: ['PodsMonitoring.uiName'],
          filters: [{ member: 'PodsMonitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
      },
    },
  ];

  const OverView_stats: iDashboardOneChart[] = [
    {
      id: 0,
      span: `12`,
      height: 400,
      name: 'Availability for deployments',
      vizState: {
        chartType: 'line',
        query: {
          measures: ['Monitoring.okRate'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: `minute`, dateRange: `Last 15 minute` }],
          dimensions: ['Monitoring.serviceName'],
          filters: [{ member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
      },
    },
    {
      id: 0,
      span: `12`,
      height: 400,
      name: 'Response time',
      vizState: {
        chartType: 'line',
        query: {
          measures: ['Monitoring.timeResponse'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: `minute`, dateRange: `Last 15 minute` }],
          dimensions: ['Monitoring.serviceName'],
          filters: [{ member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
      },
    },
  ];

  let OverView_charts = [...OverView_usage];
  if (hasMonitoring) {
    OverView_charts = [...OverView_usage, ...OverView_stats];
  }

  return <ChartDashboardMap stats={OverView_charts} />;
};
