import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { projectService } from 'services/project.service';
import { CustomWidgets } from 'form-components';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { Button, Divider, Modal, Popconfirm, Progress, Space, Spin, Typography, notification, Flex } from 'antd';
import { modalStyle, redColor, spaceWidth } from 'utils/styles';
import { DeleteServiceBtn } from './MainTab';

export interface iProjectOneServiceSettingsFormProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
  service: iCloudProjectServiceYamlSpecs;
  deployment: iDeployment;
}

interface iVType {
  type: string | number | boolean | ReactElement<string> | Iterable<ReactNode>;
  cmd: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  message: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const { Text } = Typography;

async function helmResult(resPromis: Promise<any>, title: string) {
  Modal.destroyAll();
  Modal.success({
    title: 'Loading...',
    content: <Progress percent={100} status="active" showInfo={false} />,
    icon: <Spin size="large" />,
    closable: false,
    onOk: () => resPromis,
  });
  console.log(resPromis);

  try {
    const res = await resPromis;
    const { cmd, error, logs, step1, step2 } = res?.data || {};

    Modal.destroyAll();
    if (res.error) {
      notification.error({ message: `Error: ${res.error || error}` });
    } else {
      const cmdData = cmd ? (
        <Text strong>
          {cmd} <Divider />
        </Text>
      ) : (
        ''
      );

      const errorData = error ? (
        <span style={{ color: '#BC4040' }}>
          {error} <Divider />
        </span>
      ) : null;

      const { stdout: step1Out, stderr: step1Err } = step1 || {};
      const { stdout: step2Out, stderr: step2Err } = step2 || {};

      const logsData =
        logs &&
        logs.map((v: iVType) => {
          const type = (
            <Text strong style={{ color: v.type === 'error' ? '#FF7777' : '#009688' }}>
              {v.type}
            </Text>
          );
          return (
            <Space direction="vertical">
              {v?.cmd} {type}:{v.message} <Divider />
            </Space>
          );
        });

      Modal.success({
        width: '800px',
        title: title,
        content: (
          <div style={modalStyle}>
            {cmdData}
            {errorData}
            {step1Out}
            {step1Err}
            {step2Out}
            {step2Err}
            {logsData}
          </div>
        ),
      });
    }
    return res;
  } catch (error) {
    Modal.destroyAll();
    notification.error({ message: `Error: ${error}` });
  }
}

export const ProjectOneServiceSettingsHelmTab = (props: iProjectOneServiceSettingsFormProps) => {
  const { project: projectId, serviceName, tabType, service } = props;
  const [helmConfig, setHelmConfig] = useState(null);
  const serviceOrConfigs = helmConfig === null ? service : helmConfig;
  const {
    helmRepositoryChartName: repoChartName,
    helmRepositoryName: repoName,
    helmRepositoryVersion: repoVersion,
    helmRepositoryURL: repoURL,
  } = serviceOrConfigs;

  const handleSubmit = async e => {
    const data = e.formData;
    const res = await projectService.setProjectServices(projectId.id, { tabType: tabType, name: data.name, data: data });
    if (res.error) {
      notification.error({ message: `Error: ${res.error}` });
    }
  };

  const tabConfigurations = () => {
    const addText = 'helm repo add';
    const pullText = 'helm pull';
    const updateText = 'helm update';

    const hanldeOnConfirmHelmAdd = async () => {
      await handleSubmit({ formData: serviceOrConfigs });
      const res = await projectService.serviceHelmAdd(projectId.id, serviceName);
      await helmResult(res, addText);
    };

    const hanldeOnConfirmHelmPull = async () => {
      await handleSubmit({ formData: serviceOrConfigs });
      const res = await projectService.serviceHelmPull(projectId.id, serviceName);
      await helmResult(res, pullText);
    };
    const hanldeOnConfirmHelmUpdate = async () => {
      await handleSubmit({ formData: serviceOrConfigs });
      const res = await projectService.serviceHelmUpdate(projectId.id, serviceName);
      await helmResult(res, updateText);
    };

    const commonVersion = repoVersion && `--version ${repoVersion}`;

    const addPop = (
      <Space direction="vertical">
        <Text> Run: </Text>
        <CodeLine>
          {addText} {repoName} {repoURL} {commonVersion}
        </CodeLine>
      </Space>
    );

    const pullPop = (
      <Text>
        <ul>
          <li>
            {pullText} {repoName} {repoChartName}
          </li>
          <li style={redColor}> This is not a reversible action, and it removes current helm chart files. </li>
        </ul>
      </Text>
    );

    const helmText = ['helm repo update', 'helm dependency update', 'helm dependency build'];
    const updatePop = (
      <Space direction="vertical">
        <Text> Run: </Text>
        <ul>
          {helmText.map((command, index) => (
            <li key={index}> {command} </li>
          ))}
        </ul>
      </Space>
    );

    const helmInstall = (
      <Text>
        <Text strong> helm install </Text> {repoChartName} {repoName}/{repoChartName} {commonVersion}
      </Text>
    );

    const helmCommands = [
      { type: 'add', text: addText, description: addPop, onConfirm: hanldeOnConfirmHelmAdd },
      { type: 'pull', text: pullText, description: pullPop, onConfirm: hanldeOnConfirmHelmPull, color: { danger: true } },
      { type: 'update', text: updateText, description: updatePop, onConfirm: hanldeOnConfirmHelmUpdate },
    ];

    const helmButtons = () =>
      helmCommands.map(({ type, text, description, onConfirm, color }) => {
        const addCommands = (
          <>
            {repoName} {repoURL} {commonVersion}
          </>
        );
        const pullCommands = (
          <>
            {repoName} {repoChartName}
          </>
        );
        return (
          <Space size="large" direction="horizontal" key={type}>
            <Popconfirm
              title={`Do you want to run "${text}" command?`}
              okText={type === 'pull' ? 'Remove current files and pull it again' : 'Run'}
              cancelText="Cancel"
              description={description}
              onConfirm={onConfirm}
              placement="right"
            >
              <Button {...color}> Start </Button>
            </Popconfirm>
            <Text>
              <Text strong> {text} </Text>
              {type === 'add' ? addCommands : type === 'pull' ? pullCommands : ''}
            </Text>
          </Space>
        );
      });

    return (
      <Space direction="vertical" style={spaceWidth}>
        {helmInstall}
        {helmButtons()}
      </Space>
    );
  };

  const formSchema: any = {
    type: 'object',
    properties: {
      helmRepositoryName: { type: 'string', title: 'Helm chart repository name' },
      helmRepositoryURL: { type: 'string', title: 'Helm chart repository URL' },
      helmRepositoryVersion: { type: 'string', title: 'Helm chart version' },
      helmRepositoryChartName: { type: 'string', title: 'Helm chart name' },
    },
  };

  const formUI = {
    helmRepositoryName: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart repository name here' },
    helmRepositoryURL: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart repository URL here' },
    helmRepositoryVersion: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart version here' },
    helmRepositoryChartName: { 'ui:emptyValue': '', 'ui:placeholder': 'Enter Helm chart name here' },
  };

  const handleOnChange = async e => {
    setHelmConfig(e.formData);
  };

  const handleOnError = e => console.log(`Error in form submit: ${e}`);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helmProgress, setHelmProgress] = useState(0);
  const [resultAdd, setResultAdd] = useState(null);
  const [AddError, setAddError] = useState(false);
  const [resultPull, setResultPull] = useState(null);
  const [PullError, setPullError] = useState(false);
  const [resultUpdate, setResultUpdate] = useState(null);
  const [UpdateError, setUpdateError] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);

    setResultAdd(null);
    setAddError(false);

    setResultPull(null);
    setPullError(false);

    setResultUpdate(null);
    setUpdateError(false);

    setHelmProgress(0);
  };

  const saveAndRefetch = async e => {
    await handleSubmit({ formData: serviceOrConfigs });
    setIsModalOpen(true);

    const resAdd = await projectService.serviceHelmAdd(projectId.id, serviceName);
    setResultAdd(resAdd);
    setHelmProgress(20);
    if (resAdd.error == true) {
      setAddError(true);
    }

    const resPull = await projectService.serviceHelmPull(projectId.id, serviceName);
    setResultPull(resPull);
    setHelmProgress(57);
    if (resPull.error == true) {
      setPullError(true);
    }

    const resUpdate = await projectService.serviceHelmUpdate(projectId.id, serviceName);
    setResultUpdate(resUpdate);
    setHelmProgress(100);
    if (resUpdate.error == true) {
      setUpdateError(true);
    }
  };
  const modalHelm = { ...modalStyle, margin: '10px auto', height: '500px' };

  const generateHelmLogs = resultFunction => {
    const { cmd, error, logs, step1, step2 } = resultFunction?.data || {};
    const cmdData = cmd ? (
      <Text strong>
        {cmd} <Divider />
      </Text>
    ) : (
      ''
    );
    const errorData = error ? (
      <span style={{ color: '#BC4040' }}>
        {error} <Divider />
      </span>
    ) : null;

    const { stdout: step1Out, stderr: step1Err } = step1 || {};
    const { stdout: step2Out, stderr: step2Err } = step2 || {};

    const logsData =
      logs &&
      logs.map((v: iVType) => {
        const type = (
          <Text strong style={{ color: v.type === 'error' ? '#FF7777' : '#009688' }}>
            {v.type}
          </Text>
        );
        return (
          <Space direction="vertical">
            {v?.cmd} {type}:{v.message} <Divider />
          </Space>
        );
      });
    return (
      <>
        <div>
          {cmdData}
          {errorData}
          {step1Out}
          {step1Err}
          {step2Out}
          {step2Err}
          {logsData}
        </div>
      </>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Modal
        title="Helm chart"
        open={isModalOpen}
        onCancel={handleCancel}
        width={'700px'}
        cancelText={'Close'}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Flex wrap gap="middle" justify="space-around">
          {helmProgress == 100 ? (
            ''
          ) : (
            <Progress
              percent={helmProgress}
              percentPosition={{ align: 'center', type: 'outer' }}
              size="default"
              strokeColor="#52c41a"
              status={'active'}
            />
          )}
        </Flex>
        <div style={modalHelm}>
          {resultAdd ? (
            <>
              <div style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>Helm Add :</div>
              <br />
              {generateHelmLogs(resultAdd)}
              {AddError ? (
                <div style={{ fontSize: '18px', fontWeight: 'bold', color: 'red' }}>Helm Add was executed with error</div>
              ) : (
                <div style={{ fontSize: '18px', fontWeight: 'bold', color: '#52c41a' }}>Helm Add was executed successfully</div>
              )}
              <br />
            </>
          ) : (
            ''
          )}
          {resultPull ? (
            <>
              <div style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>Helm Pull :</div>
              <br />
              {generateHelmLogs(resultPull)}
              {PullError ? (
                <div style={{ fontSize: '18px', fontWeight: 'bold', color: 'red' }}>Helm Pull was executed with error</div>
              ) : (
                <div style={{ fontSize: '18px', fontWeight: 'bold', color: '#52c41a' }}>Helm Pull was executed successfully</div>
              )}
              <br />
            </>
          ) : (
            ''
          )}
          {resultUpdate ? (
            <>
              <div style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>Helm Update :</div>
              <br />
              {generateHelmLogs(resultUpdate)}
              {UpdateError ? (
                <div style={{ fontSize: '18px', fontWeight: 'bold', color: 'red' }}>Helm update was executed with error</div>
              ) : (
                <div style={{ fontSize: '18px', fontWeight: 'bold', color: '#52c41a' }}>Helm update was executed successfully</div>
              )}
              <br />
            </>
          ) : (
            ''
          )}
        </div>
      </Modal>
      <Form
        widgets={CustomWidgets}
        formData={serviceOrConfigs}
        schema={formSchema}
        uiSchema={formUI}
        validator={validator}
        onChange={handleOnChange}
        onSubmit={handleSubmit}
        onError={handleOnError}
      >
        <BottomButtons extra={<DeleteServiceBtn serviceName={serviceName} projectId={projectId.id} deploymentId={props.deployment.id} />}>
          <Button type="primary" onClick={saveAndRefetch}>
            Save {`&`} Re-fetch helm chart
          </Button>
          <Button type="default" htmlType="submit">
            Save
          </Button>
        </BottomButtons>
      </Form>
      {tabConfigurations()}
    </Space>
  );
};
