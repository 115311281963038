import { ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Tag, Modal, notification, Skeleton } from 'antd';
import { TipBottom } from 'components/SharedComponents/Tooltip/Tooltip';
import gql from 'graphql-tag';
import { useState } from 'react';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { useAuthedMutation } from 'utils/qlAuth';

function DeploymentApprove({ user, needApprove, deploymentId, refetch }) {
  const [approveDeployment, { data, loading, error }] = useAuthedMutation(gql`
    mutation DeploymentsController_approveToRun($deploymentId: Int!, $approve: Boolean) {
      DeploymentsController_approveToRun(deploymentId: $deploymentId, approve: $approve) {
        status
        error
      }
    }
  `);

  const handleApproveDeployment = () => approveDeployment({ variables: { deploymentId: deploymentId, approve: true } });

  const approveDeploymentModal = async () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'Do you want to approve this Application?',
      centered: true,
      onOk: async () => {
        await handleApproveDeployment();
        if (error) {
          notification.error({ message: `Error - ${error.message}` });
          return;
        }
        refetch();
        notification.success({ description: `Users now have access to the application`, message: 'Application Approved' });
      },
    });
  };

  const OpenModal = e => {
    e.preventDefault();
    approveDeploymentModal();
  };
  return needApprove ? (
    <>
      <Tag color="gold" icon={<ClockCircleOutlined />}>
        <TipBottom tip="Application Needs to be approved by Admin or Devops Person">Waiting for Approval</TipBottom>
      </Tag>
      {userHasPermission(user, UserPermissions.ApproveDeployments) ? (
        <Tag color="success" icon={<CheckCircleOutlined />} onClick={OpenModal}>
          <TipBottom tip="Approve this application to allow users to start accessing and using it">Approve this application</TipBottom>
        </Tag>
      ) : null}
    </>
  ) : null;
}

export default DeploymentApprove;
