import { Button, Result, Space, Typography } from 'antd';
import { CaretLeftFilled } from '@ant-design/icons';
import { blackText, noPageFoundStyle } from 'utils/styles';
import { getMainDomainUrl } from 'utils/common';
const { Title, Text } = Typography;

interface iNotFoundPageProps {}

const NoTenantPage = (props: iNotFoundPageProps) => {
  const resultTitle = (
    <Title level={4} style={blackText}>
      {'This tenant does not exist'}
    </Title>
  );

  const resultSubTitle = <Text style={blackText}>{`This tenant you are looking for does not exist.`}</Text>;

  return (
    <Space style={noPageFoundStyle}>
      <Result
        status="404"
        title={resultTitle}
        subTitle={resultSubTitle}
        extra={
          <a href={`https://${getMainDomainUrl()}`}>
            <Button type="primary" icon={<CaretLeftFilled />}>
              {`Login`}
            </Button>
          </a>
        }
      />
    </Space>
  );
};

export default NoTenantPage;
