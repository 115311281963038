import gql from 'graphql-tag';
import { iRegionModel } from 'shared/deployment';
import { useAuthedMutation } from 'utils/qlAuth';
import { useHistory } from 'react-router-dom';
import { regionsService } from 'services/regions.service';
import { Alert, Button, Collapse, CollapseProps, Popconfirm, Space, Switch, Typography, message, notification } from 'antd';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { WarningFilled } from '@ant-design/icons';
import { RegionUpdateCommands } from './RegionInstallCommands/RegionInstallCommands';

export interface iClusterDeleteButtonProps {
  region: iRegionModel;
}

export interface iClusterDangerZoneProps {
  region: iRegionModel;
}

const { Text } = Typography;

export const ClusterDeleteButton = (props: iClusterDeleteButtonProps) => {
  const history = useHistory();
  const [removeCluster, removeClusterResult] = useAuthedMutation(gql`
    mutation RegionsController_removeCluster($regionId: Int!) {
      RegionsController_removeCluster(regionId: $regionId) {
        error
        status
      }
    }
  `);

  let locked = removeClusterResult?.loading ?? false;
  if (removeClusterResult?.data?.RegionsController_removeCluster) {
    const { status, error } = removeClusterResult.data.RegionsController_removeCluster;
    if (status === true) {
      history.push(`/clusters`);
    }
    if (error) {
      message.error(error);
      locked = false;
    }
  }

  const handdleRemoveCluster = () => removeCluster({ variables: { regionId: Number(props.region.id) } });

  return (
    <Popconfirm title="Remove cluster from unifie dashboard?" description={null} onConfirm={handdleRemoveCluster} okText="Remove" cancelText="Cancel">
      <Button type="primary" disabled={locked} danger style={buttonBorder}>
        Delete
      </Button>
    </Popconfirm>
  );
};

export const ClusterDangerZone = (props: iClusterDangerZoneProps) => {
  const region = props.region;

  const clusterSaveConfig = async (values: any) => {
    const res = await regionsService.updateOwnRegion(region.id, values);
    if (res.error) notification.error({ message: `Error - ${res.error}` });
    else notification.success({ message: `Ready` });
  };

  const removeClusterLabel = (
    <Space direction="horizontal">
      <Text type="danger">
        <WarningFilled />
      </Text>
      <Text strong type="danger">
        Delete {region.name} Cluster?
      </Text>
    </Space>
  );

  const removeClusterChildren = (
    <Alert
      type="error"
      message={`Deleting ${region.name} cluster`}
      description={`This is not a reversible action. Deletion will result in Data loss.`}
      action={<ClusterDeleteButton region={region} />}
    />
  );

  const allowToAddDeploymentsLabel = (
    <Space direction="horizontal">
      <Text type="success">
        <WarningFilled />
      </Text>
      <Text strong type="success">
        Allow to add Deployments to the {region.name} Cluster?
      </Text>
    </Space>
  );

  const allowToAddDeploymentsChildren = (
    <Alert
      type="info"
      message={`Allow to add deployments to the ${region.name} cluster?`}
      description={`It will not change the current state of the Cluster. It will only hide this Cluster from Deployments creation form.`}
      action={
        <Switch
          unCheckedChildren="Disabled"
          checkedChildren="Enabled"
          defaultChecked={region.allowToAddDeployments}
          onChange={checked => clusterSaveConfig({ allowToAddDeployments: checked })}
        />
      }
    />
  );

  const items: CollapseProps['items'] = [
    { key: 'removeCluster', label: removeClusterLabel, children: removeClusterChildren },
    { key: 'allowToAddDeployments', label: allowToAddDeploymentsLabel, children: allowToAddDeploymentsChildren },
  ];

  return (
    <>
      <RegionUpdateCommands region={region} />
      <Collapse size="small" items={items} accordion ghost style={spaceWidth} />
    </>
  );
};
