import { Typography, Button, Space, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { TipRight } from '../Tooltip/Tooltip';

export const CodeLine = (props: { children: any; title?: string }) => {
  const handleCopy = () => {
    let childrenText = props.children;
    if (Array.isArray(props.children)) {
      childrenText = props.children.join('');
    }
    navigator.clipboard.writeText(childrenText).then(() => message.success('Snippet copied successfully...'));
  };

  return (
    <Space direction="horizontal">
      {props.title && <Typography.Text>{props.title}</Typography.Text>}
      <TipRight tip="Click to copy this snippet">
        <Button type="link" icon={<CopyOutlined />} onClick={handleCopy} />
      </TipRight>
      <Typography.Paragraph>
        <pre>{props.children}</pre>
      </Typography.Paragraph>
    </Space>
  );
};
