import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Typography, Form, Select, Radio, Space, Alert, Button } from 'antd';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { ProjectGitConfigurationHelp } from './ProjectGitConfigurationHelp';
import { UnifieQuestionCircleIcon } from 'components/CustomIcons/CustomIcons';

const { Text, Title } = Typography;

export const GitHelpInfoAlert = () => {
  const alertMessage = (
    <Space direction="vertical" data-qa="GitHelpInfoAlert">
      <Text>
        You can use Github and Gitlab Repositories. You can create token in your
        <TipTop tip="Click here to login through your GitHub Account">
          <Text strong>
            <a href={'https://github.com/settings/tokens?type=beta'} target="_blank">
              &nbsp;GitHub&nbsp;
            </a>
          </Text>
        </TipTop>
        or
        <TipTop tip="Click here to login through your GitLab Account">
          <Text strong>
            <a href={'https://docs.gitlab.com/ee/user/project/settings/project_access_tokens.html#create-a-project-access-token'} target="_blank">
              &nbsp;GitLab&nbsp;
            </a>
          </Text>
        </TipTop>
        accounts. Project will use write and read access.
      </Text>
    </Space>
  );

  return (
    <Alert
      showIcon
      type="info"
      message={`We recommend to use Git version control system for your template. Especially if you are working in a team.`}
      description={alertMessage}
      action={
        <ProjectGitConfigurationHelp
          btnText={
            <Button>
              How to use <UnifieQuestionCircleIcon />
            </Button>
          }
        />
      }
    />
  );
};
