import { Link, useHistory } from 'react-router-dom';
import { useUser } from '../../../utils/common';
import { authService } from '../../../services/auth.service';
import { NhHelpDrawer } from 'components/Help/NhHelpDrawer';
import { EditFilled, GithubFilled, KeyOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Button, Flex, Popover, Space, Spin, Typography } from 'antd';
import { formatUserName } from 'utils/nameFormat';
import { buttonColor } from 'utils/styles';
import { TipLeft } from '../Tooltip/Tooltip';

const { Text } = Typography;

export default function NhHeader() {
  const user = useUser();
  const history = useHistory();

  const userLogout = async () => {
    await authService.logout();
    window.location.reload();
  };

  const userName = (welcome: boolean) => <Text strong> {`${welcome ? 'Welcome,' : ''} ${formatUserName(user.email)}`} </Text>;

  const userDetails = () =>
    user?.email && (
      <Flex gap="middle" justify="space-between">
        <Space onClick={() => history.push(`/users/${user.id}`)}>
          <Avatar src={user.avatar} shape="square" size={52} />
          <Space direction="vertical">
            {userName(false)}
            <Text> {user.email} </Text>
          </Space>
        </Space>
        <TipLeft tip="Edit your profile">
          <Button type="text" icon={<EditFilled style={buttonColor} />} onClick={() => history.push(`/users/${user.id}`)} />
        </TipLeft>
      </Flex>
    );

  const userSession = () => (
    <Flex gap="middle" justify="space-between">
      <Link to="/changePassword">
        <Button block data-cy="changePasswordLink" icon={<KeyOutlined rotate={225} />} iconPosition="end">
          Change password
        </Button>
      </Link>
      <Button block data-cy="logOut" icon={<LogoutOutlined />} iconPosition="end" onClick={userLogout}>
        Sign out
      </Button>
    </Flex>
  );

  const githubButton = (
    <Button block data-cy="githubLogin" icon={<GithubFilled />} iconPosition="end" disabled={!!user?.githubLogin}>
      {user?.githubLogin ? (
        'Your account is linked with GitHub'
      ) : (
        <a href="/api/github/oauth/link" target="_blank">
          Link account with GitHub
        </a>
      )}
    </Button>
  );

  const userGithub = () => (user && !user.githubLogin ? githubButton : null);

  const headerContent = () => (
    <Space direction="vertical">
      {userDetails()}
      {userSession()}
      {userGithub()}
    </Space>
  );

  const userContent = () => (
    <Popover placement="bottomRight" content={headerContent()}>
      <a data-cy="user" href="/" onClick={e => e.preventDefault()}>
        {user?.email ? userName(true) : <Spin />}
      </a>
    </Popover>
  );

  return (
    <Space direction="horizontal">
      <NhHelpDrawer />
      {userContent()}
    </Space>
  );
}
