import _ from 'lodash';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { iMonitoringRoute } from 'shared/project.interface';
import { TitleUIRow } from 'layout/TitleUI';
import { Button, Collapse, Form, Input, InputNumber, Skeleton, Space, notification, Typography, Row, Col, Flex } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { spaceWidth, buttonWidth, fieldWidth } from 'utils/styles';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { DeleteServiceBtn } from './MainTab';
import { DeployButton } from 'components/Deployments/Setting/new-deployment/DeployPage';
import gql from 'graphql-tag';
import { useAuthedMutation } from 'utils/qlAuth';

export interface ProjectOneServiceSettingsFormMonitorProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
  deployment: iDeployment;
}

const { Panel } = Collapse;
const { Text } = Typography;
const { Item, List } = Form;

const showError = (message: string) => {
  notification.error({
    key: 'MissingRequiredFields',
    message: 'Please check your routes config.',
    description: message,
  });
  return true;
};

export const ProjectOneServiceSettingsFormMonitor = (props: ProjectOneServiceSettingsFormMonitorProps) => {
  const [setServiceMonitoring, setServiceMonitoringResponse] = useAuthedMutation(
    gql`
      mutation MonitoringController_setServiceMonitoring($projectId: Int!, $serviceName: String!, $monitoring: [iMonitoringRouteInput]) {
        MonitoringController_setServiceMonitoring(projectId: $projectId, serviceName: $serviceName, monitoring: $monitoring)
      }
    `,
  );
  const projectId = props.project.id;
  const serviceName = props.serviceName;
  const tabType = props.tabType;
  const [form] = Form.useForm();

  const [service, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(projectId, serviceName),
    [projectId, serviceName, tabType],
  );

  if (serviceError || serviceLoader) return <Skeleton active={true} loading={true} />;

  const monitoringInputs = [
    { label: 'Host name', name: 'host', placeholder: 'Enter host name', defaultValue: '{{ .deployment.domain }}', type: 'Input' },
    { label: 'Path', name: 'path', placeholder: 'Enter path here', defaultValue: '/', type: 'Input' },
    { label: 'Timeout(ms)', name: 'timeout', defaultValue: 30000, type: 'InputNumber' },
  ];

  const panelHeader = <Text strong> Health check </Text>;

  const routesListForm = (
    <List name="routes">
      {(subFields, subOpt) => (
        <Space direction="vertical" style={spaceWidth}>
          <Collapse size="small" defaultActiveKey={subFields.map(s => s.name)}>
            {subFields.map(subField => (
              <Panel header={panelHeader} key={subField.name} extra={<CloseOutlined onClick={() => subOpt.remove(subField.name)} />}>
                <Space direction="vertical" style={spaceWidth}>
                  {monitoringInputs.map(field => (
                    <Row key={field.name}>
                      <Col span={12}>
                        <Text strong> {field.label} </Text>
                      </Col>
                      <Col span={12}>
                        <Item noStyle name={[subField.name, field.name]}>
                          {field.type === 'Input' && <Input placeholder={field.placeholder} style={fieldWidth} defaultValue={field.defaultValue} />}
                          {field.type === 'InputNumber' && (
                            <InputNumber placeholder={field.placeholder} style={fieldWidth} defaultValue={field.defaultValue} />
                          )}
                        </Item>
                      </Col>
                    </Row>
                  ))}
                </Space>
              </Panel>
            ))}
          </Collapse>
          <Button type="primary" onClick={() => subOpt.add()} style={buttonWidth}>
            Add health check
          </Button>
        </Space>
      )}
    </List>
  );

  const handleSubmit = async (): Promise<boolean> => {
    const data = form.getFieldsValue();
    debugger;
    const serviceObj = {
      monitoring: data.routes.map((monitoringRoute): iMonitoringRoute => {
        return {
          host: monitoringRoute?.host || '{{ .deployment.domain }}',
          path: monitoringRoute?.path || '/',
          timeout: monitoringRoute?.timeout || 30000,
        };
      }),
    };
    const validate = serviceObj.monitoring.find(route => {
      return !route.host
        ? showError('Host name is required.')
        : !route.path
        ? showError('Path is required.')
        : !route.timeout
        ? showError('Timeout is required.')
        : false;
    });

    if (validate) {
      return false;
    }
    console.log(`handleSubmit: `, serviceObj);
    await setServiceMonitoring({
      variables: { projectId, serviceName, monitoring: serviceObj.monitoring },
    });
    const { error } = setServiceMonitoringResponse;
    if (error) {
      notification.error({ message: `Error - ${error}` });
      return false;
    }
    notification.success({ message: `Ready` });
    return true;
  };

  const formRoutes = (service?.monitoring || []).map((route: iMonitoringRoute, index: number) => {
    return {
      key: index,
      host: route?.host || `{{ .deployment.domain }}`,
      path: route?.path || `/`,
      timeout: route?.timeout || 30000,
    };
  });

  return (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title={`Monitoring configuration for ${serviceName}`} />
      <Text />
      <Form
        onFinish={handleSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        name="dynamic_form_complex"
        autoComplete="off"
        initialValues={{ routes: formRoutes }}
        style={spaceWidth}
        form={form}
      >
        {routesListForm}
        <Text /> <Text /> <Text /> <Text />
        <Item>
          <BottomButtons
            extra={<DeleteServiceBtn serviceName={serviceName} projectId={props.deployment.projectId} deploymentId={props.deployment.id} />}
          >
            <Button type="primary" htmlType="submit">
              Save
            </Button>

            <DeployButton deployment={props.deployment} beforeDeploy={handleSubmit} />
          </BottomButtons>
        </Item>
      </Form>
    </Space>
  );
};
