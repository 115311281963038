import './Authentication.scss';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import { handleInputChange, useApiQuery } from '../../utils/common';
import { iProject } from 'interface/project';
import { projectService } from 'services/project.service';
import { Typography, Button, Input, notification, Space, Skeleton, Tag, Avatar } from 'antd';
import { capitalLetter } from 'utils/nameFormat';
import { PROJECT_NAME } from 'interface/common';
import { AuthenticationForm, ImageA, ImageD, SignupLink, TextFind } from 'components/SharedComponents/AuthComp/AuthComp';
import Markdown from 'react-markdown';

const { Title, Text } = Typography;

const BigDeployButton = (props: { projectUuid: string }) => {
  const handleDeployClick = () => {
    window.location.replace(`/#/new-application/from-public-template/${props.projectUuid}`);
  };
  return (
    <Button type="primary" onClick={handleDeployClick} style={{ minWidth: '200px' }}>
      Deploy
    </Button>
  );
};

const FindTenantFormForProjectDeploy = (props: { projectUuid: string }) => {
  const [email, setEmail] = useState('');
  const [lastEmail, setLastEmail] = useState('');
  const [tenants, setTenants] = useState([]);
  const [showSignUp, setShowSignUp] = useState(false);

  const onTenantsRequest = async email => {
    const { error, status, data } = await authService.tenants(email);
    if (error) {
      notification.error({ key: 'Login', message: `status ${status}`, description: data.message });
      return null;
    }

    window.localStorage.setItem('LastTenant_email', email);
    setLastEmail(email);

    if (data.length === 0) {
      notification.warning({ key: 'Login', message: `Your email was not found. Would you like to sign up? to ${PROJECT_NAME}` });
      setShowSignUp(true);
      setTenants([]);
      return null;
    }
    setShowSignUp(false);
    setTenants(data);

    // return error
    //   ? (notification.error({ key: 'Login', message: `status ${status}`, description: data.message }), null)
    //   : // Set the email in local storage to remember the last used email
    //     (window.localStorage.setItem('LastTenant_email', email),
    //     setLastEmail(email),
    //     data.length === 0
    //       ? notification.warning({
    //           key: 'Login',
    //           message: `Your email was not found. Would you like to sign up? to ${PROJECT_NAME}`,
    //         })
    //       : null,
    //     setTenants(data));
  };

  useEffect(() => {
    const lastUsedEmail = window.localStorage.getItem('LastTenant_email') || undefined;
    lastUsedEmail ? onTenantsRequest(lastUsedEmail) : null;
  }, []);

  const handleFormSubmit = () => onTenantsRequest({ email });

  const showLoginButton = email && (lastEmail !== email || tenants.length == 0);

  const deployButton = () =>
    lastEmail !== email && !!email
      ? null
      : tenants.map(tenant => {
          const handleDeploy = () =>
            window.location.replace(
              `https://${tenant}.${window.location.host.replace('auth.', '')}/#/new-application/from-public-template/${props.projectUuid}`,
            );
          return (
            <Button type="primary" onClick={handleDeploy} style={{ minWidth: '200px' }}>
              Deploy in account: {capitalLetter(tenant)}
            </Button>
          );
        });

  return (
    <form onSubmit={handleFormSubmit}>
      <Space direction="vertical">
        <Text strong> Email </Text>
        <Input placeholder="Enter email here" onChange={handleInputChange(setEmail)} value={email} />

        <Space direction="horizontal">
          {showLoginButton && (
            <Button type="primary" disabled={lastEmail === email} onClick={() => onTenantsRequest(email)} style={{ minWidth: '200px' }}>
              Find account
            </Button>
          )}
          {showSignUp && (
            <Link to={`/signup`}>
              <Button type="primary">Sign up</Button>
            </Link>
          )}
        </Space>
        {deployButton()}
      </Space>
    </form>
  );
};

export default function DeployPublicProjectPage() {
  const params: any = useParams();
  const projectUUid = params?.projectUUID || '';
  const [projectData, fError, fLoader] = useApiQuery(() => projectService.getPublicProjectByUUID(projectUUid), [projectUUid]);
  const project: iProject = projectData;
  const needToFindTenant = 1; //[`www`, `api`, `auth`, `sso`].includes(window.location.host.split('.')[0]);

  if (fLoader) {
    return (
      <>
        <ImageA />
        <SignupLink />
        <AuthenticationForm>
          <Skeleton active={true} loading={true}></Skeleton>
        </AuthenticationForm>
        <TextFind />
      </>
    );
  }

  if (projectData?.error || fError) {
    return (
      <>
        <ImageA />
        <SignupLink />
        <AuthenticationForm>
          <Title level={3}> Error </Title>
          <Text> {projectData?.error} </Text>
        </AuthenticationForm>
        <TextFind />
      </>
    );
  }

  return (
    <>
      <ImageD />
      <SignupLink />
      <div
        style={{
          paddingLeft: '24px',
          paddingTop: '24px',
        }}
      >
        <PublicProjectDetails project={project} />
        <br />
        <br />
        <br />
        <Title level={5}> You can deploy this application in your kubernetes cluster </Title>
        {needToFindTenant ? <FindTenantFormForProjectDeploy projectUuid={projectUUid} /> : <BigDeployButton projectUuid={projectUUid} />}
      </div>
      <TextFind />
    </>
  );
}

const PublicProjectDetails = (props: { project: iProject }) => {
  const { project } = props;
  return (
    <Space direction="vertical">
      <Title level={2}>
        <Avatar src={project.logo} shape="square" size={52} /> {project.title}
      </Title>
      <Markdown>{project.description}</Markdown>
      <Space direction="horizontal">
        {project.tags.map((tag, index) => (
          <Tag key={index}> {tag} </Tag>
        ))}
      </Space>
    </Space>
  );
};
