import { ReactElement, useState } from 'react';
import { ProjectApiKeys_newApiKey } from 'queries/queries';
import { iProject } from 'interface/project';
import { useAuthedMutation } from 'utils/qlAuth';
import { makeId } from 'utils/common';
import { Editor } from '@monaco-editor/react';
import { Alert, Collapse, notification, Button, Modal, Input, Typography, Space } from 'antd';
import { blackText, buttonWidth, spaceWidth } from 'utils/styles';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import { iProjectModel } from 'shared/deployment';

export interface IProjectCiCd {
  project: iProjectModel;
}

const { Title, Text } = Typography;
const { Panel } = Collapse;

export const ProjectCiCd = (props: IProjectCiCd): ReactElement => {
  const [mutateFunction, saveRes] = useAuthedMutation(ProjectApiKeys_newApiKey);
  const [myNewKey, setMyNewKey] = useState(`YOUR_API_KEY`);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const apiUser = `project${props.project.id}`;

  const handleSubmit = async () => {
    const handleOnOk = async () => {
      const myNewKey = makeId(16);
      const modalContent = (
        <>
          <Text> Your new API key </Text>
          <Input value={myNewKey} />
          <Text> Please copy it now. You will not be able to see it again. </Text>
        </>
      );
      await mutateFunction({ variables: { key: myNewKey, projectId: props.project.id } });
      saveRes.error ? notification.error({ message: `Error - ${saveRes.error}` }) : notification.success({ message: `Ready` });
      setMyNewKey(myNewKey);
      Modal.confirm({ title: 'Confirm', content: modalContent });
    };
    Modal.confirm({
      title: 'Confirm',
      content: 'You can only have one API key for a project. The previous key will be deleted. Are you sure you want to generate a new key?',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: handleOnOk,
    });
  };

  const editorTab = () => (
    <>
      <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      <Text />
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        <Editor
          // height="calc(200px)"
          height={isFullscreen ? '100vh' : '70vh'}
          width={`100%`}
          language={`sh`}
          theme={`GitHub`}
          defaultValue=""
          // Do not remove `\\` from the code below. It is important for bash lang
          value={`export SERVICE_NAME=MY_SERVICE_NAME
export DOCKER_IMAGE_NAME=059920143366.dkr.ecr.us-east-1.amazonaws.com/docker-static-images:$CI_PIPELINE_ID
curl --user ${apiUser}:${myNewKey} \\
--data-urlencode "projectId=${props.project.id}" \\
--data-urlencode "title=$CI_COMMIT_TITLE" \\
--data-urlencode "message=$CI_COMMIT_MESSAGE" \\
"${window.location.origin}/versions/update?channel=Prod&name=$CI_COMMIT_BRANCH&build=$CI_PIPELINE_ID&image=$SERVICE_NAME&url=$DOCKER_IMAGE_NAME"`}
        />
      </FullScreenEditor>
    </>
  );

  const commonData = () => (
    <Space direction="vertical">
      <Text> Add this code to your pipline after you build and push your docker image to container registry. </Text>
      <Text> Please replace: </Text>
    </Space>
  );

  const gitLabPanel = () => {
    const gitlabContent = [
      { key: 'MY_SERVICE_NAME', text: 'your service name.' },
      { key: 'DOCKER_IMAGE_NAME', text: 'full path to docker image in container registry.' },
    ];
    const gitlabData = gitlabContent.map((gitlabContent, index) => (
      <ul>
        <li key={index}>
          <Text strong> {gitlabContent.key}: </Text> &nbsp; {gitlabContent.text}
        </li>
      </ul>
    ));
    return (
      <Panel key="gitlab" header={<Text strong> Gitlab Integration </Text>}>
        <Space direction="vertical" style={spaceWidth}>
          {commonData()}
          {gitlabData}
          {editorTab()}
          <Text> GitLab pipeline already has a variable CI_PIPELINE_ID and CI_COMMIT_BRANCH. You can use it to pass build number to the API. </Text>
        </Space>
      </Panel>
    );
  };

  const othersPanel = () => {
    const othersContent = [
      { key: 'MY_SERVICE_NAME', text: 'your service name.' },
      { key: 'DOCKER_IMAGE_NAME', text: 'full path to docker image in container registry.' },
      { key: 'CI_COMMIT_TITLE', text: 'name for your new build.' },
      { key: 'CI_COMMIT_MESSAGE', text: 'description for your new build.' },
      { key: 'CI_COMMIT_BRANCH', text: 'branch name in your git.' },
      { key: 'CI_PIPELINE_ID', text: 'pipeline id or any other unique identification.' },
    ];
    const othersData = othersContent.map((othersContent, index) => (
      <ul>
        <li key={index}>
          <Text strong> {othersContent.key}: </Text> &nbsp; {othersContent.text}
        </li>
      </ul>
    ));
    return (
      <Panel key="other" header={<Text strong> Other </Text>}>
        <Space direction="vertical" style={spaceWidth}>
          {commonData()}
          {othersData}
          {editorTab()}
        </Space>
      </Panel>
    );
  };
  const alertMessage = (
    <Text>
      It is very useful to &nbsp;
      <a target="_blank" href="https://www.unifie.cloud/doc/docs/User-Guide/CI-CD/">
        configure integration with your CI/CD tool
      </a>
      .
    </Text>
  );
  const alertAction = (
    <Button type="primary" danger onClick={handleSubmit} style={buttonWidth}>
      Create API Key
    </Button>
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Alert showIcon type="info" message={alertMessage} action={alertAction} />
      <Collapse size="small" defaultActiveKey={[]} ghost accordion>
        {gitLabPanel()}
        {othersPanel()}

        <Panel
          key="Troubleshooting"
          header={
            <Text strong style={blackText}>
              Troubleshooting
            </Text>
          }
        >
          <Space direction="vertical" style={spaceWidth}>
            <Title level={5}> Possible solutions </Title>
            <ul>
              <li>
                Read How to use &nbsp;
                <a target="_blank" href="https://www.unifie.cloud/doc/docs/User-Guide/CI-CD/">
                  CI/CD integration &nbsp;
                </a>
              </li>
              <li> Check if your CI/CD integration is working correctly. </li>
              <li> Call api for creating new version at least once. </li>
              <li> Check the response for the api call. </li>
              <li> If you get 403 or 401 errors - Check your api key that you use. </li>
              <li> If you get 404 error - Check the project id. </li>
            </ul>
          </Space>
        </Panel>
      </Collapse>
    </Space>
  );
};
