import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { Button, notification, Skeleton, Space, Typography } from 'antd';
import { spaceWidth } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import { iDeployment } from 'shared/deployment';
import { DeployButton } from 'components/Deployments/Setting/new-deployment/DeployPage';
import { getThemeName } from 'utils/theme';

export interface OneFileProps {
  deployment: iDeployment;
  fileName: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  height?: string;
  hideModal?: any;
}

/**
 * @param param0
 * @returns
 * @link https://www.freecodecamp.org/news/how-to-build-react-based-code-editor/
 * @link https://www.npmjs.com/package/react-simple-code-editor
 */

const { Text } = Typography;

export const OneFile = (props: OneFileProps) => {
  const project = props.deployment.ProjectModel;

  const { fileName } = props;
  const [content, fError, fLoader] = useApiQuery(() => projectService.getFileContent(project.id, fileName), [fileName]);
  const [isChanged, setChanged] = useState(false);
  const [newCode, setNewCode] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  if (fLoader || fError) return <Skeleton active={true} loading={true} />;

  const editorData = () => {
    const handleEditorChange = (value: string) => {
      setChanged(true);
      setNewCode(value);
      props.onChange ? props.onChange(value) : null;
    };

    const fileExt = fileName.replace(/^.*\./, '');

    const language =
      fileExt === 'md' ? 'markdown' : fileExt === 'js' ? 'javascript' : fileExt === 'yaml' ? 'yaml' : fileExt === 'json' ? 'json' : 'javascript';

    const editorOptions: any = { scrollbar: { horizontal: 'hidden', vertical: 'hidden' } };

    return (
      <Editor
        height={props.height || 'calc(100vh - 215px)'}
        width={`100%`}
        language={language}
        value={content.data || ''}
        theme={getThemeName() === 'dark' ? 'vs-dark' : 'GitHub'}
        defaultValue=""
        onChange={handleEditorChange}
        options={editorOptions}
      />
    );
  };

  const saveButton = () => {
    const handleSaveFile = async value => {
      setChanged(false);
      props.onSubmit ? props.onSubmit(value) : null;
      await projectService.setFileContent(project.id, fileName, newCode);
      notification.success({ message: `Saved` });
      props.hideModal ? props.hideModal() : null;
      return true;
    };
    return (
      <BottomButtons>
        <Space>
          <Button type="primary" htmlType="submit" onClick={handleSaveFile} disabled={!isChanged}>
            Save {fileName}
          </Button>
          <DeployButton deployment={props.deployment} beforeDeploy={handleSaveFile} disabled={!isChanged} />
        </Space>
      </BottomButtons>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      <Text />
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        {editorData()}
      </FullScreenEditor>
      {saveButton()}
    </Space>
  );
};
