import gql from 'graphql-tag';
import { useState } from 'react';
import { iDeployment, iRegionModel } from 'shared/deployment';
import { Alert, Button, Input, Modal, notification, Select, Skeleton, Space, Tag, Typography } from 'antd';
import { bannerStyle, buttonSize, buttonWidth, dropdownStyle, floatingRight, redColor, spaceWidth } from 'utils/styles';
import { NewClusterCreation } from 'components/Clusters/ClustersList';
import { TipRight } from 'components/SharedComponents/Tooltip/Tooltip';
import { useGqlDeploymentById, deploymentService } from 'services/deployment.service';
import { useAuthedQuery } from 'utils/qlAuth';
import { DeleteDeployment } from '../DeleteDeployment';
import { PauseApplicationBtn } from './PauseApplicationBtn ';
import { AutoDeploySettings } from './AutoDeploySettings';
import { ShareProject, ShareProjectModalBtn } from 'components/Projects/Settings/ShareProject';
import { UnifieQuestionCircleIcon } from 'components/CustomIcons/CustomIcons';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';

const { Text } = Typography;
const { Option } = Select;

export const DeleteTab = (props: { id: number }) => {
  const dpQuery = useGqlDeploymentById(props.id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};

  const [regionDeployment, setRegionDeployment] = useState(deployment?.region);
  const { data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList($projectId: Int!) {
        ProjectController_getProjectList {
          id
          name
          title
          description
          logo
        }
        RegionsController_regionsForProject(projectId: $projectId) {
          regions {
            id
            tenant
            name
            title
            kubernetesVersion
            cloudProvider
            isPublic
          }
          disabledRegions
        }
      }
    `,
    { skip: !Number(deployment?.projectId), variables: { projectId: Number(deployment?.projectId) } },
  );

  const [inputValue, setInputValue] = useState<string>('');

  if (dpQuery.loading) return <Skeleton active={true} loading={true} />;

  const regions = data?.RegionsController_regionsForProject?.regions || [];
  const disabledRegions = data?.RegionsController_regionsForProject?.disabledRegions || [];

  const saveDeploymentRegion = async () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          Do you want to move this application to a different cluster?
          <br />
          It will create application with same settings in new cluster and delete this one.
          <br />
          It will not move data from persistent volumes. You may loose data.
          <br />
        </>
      ),
      onOk: async () => {
        await deploymentService.updateDeployment(deployment.id, { region: regionDeployment });
        dpQuery.refetch();
        notification.success({ description: `Saved`, message: '' });
      },
    });
  };

  const deleteApplications = () => {
    const handleInputChange = e => {
      setInputValue(e.target.value);
      e.preventDefault();
    };

    const alertMessage = <Text strong> Delete Application </Text>;

    const alertDescription = (
      <Space direction="vertical">
        <Text> Deleting this Application is permanent and cannot be undone. All associated data will be lost. </Text>
        <Text>
          <Text style={redColor}> This cannot be undone. </Text> Type <Text strong> Confirm </Text> to delete.
        </Text>
        <Input placeholder="Enter confirmation text here..." value={inputValue} onChange={handleInputChange} style={dropdownStyle} />
      </Space>
    );

    const enabledButton = <DeleteDeployment id={deployment.id} confirmDelete={inputValue === 'Confirm' ? false : true} />;

    return <Alert message={alertMessage} description={alertDescription} action={enabledButton} style={bannerStyle} />;
  };

  const moveApplications = () => {
    const moveCluster = (
      <Space direction="horizontal">
        <Space>
          <Select defaultValue={deployment.region} style={dropdownStyle} onChange={setRegionDeployment} placeholder="Choose Cluster Here">
            <Option value={null} key={0}>
              No Cluster is selected
            </Option>
            {[...regions]
              .sort((a, b) => (b.id - a.id !== 0 ? b.id - a.id : a.isPublic - b.isPublic))
              .map(({ id, isPublic, kubernetesVersion, title }: iRegionModel) => (
                <Option value={id} key={id} disabled={disabledRegions.includes(id)}>
                  <Tag color={isPublic ? 'grey' : 'green'} title="Kubernetes version">
                    {isPublic ? 'Shared' : 'Private'}
                  </Tag>
                  <Tag color={kubernetesVersion ? 'green' : 'red'} title="Kubernetes version" style={{ ...floatingRight, top: 5 }}>
                    Kubernetes {kubernetesVersion || 'N/A'}
                  </Tag>
                  {title} {!disabledRegions.includes(id) ? '' : 'Not available, in this Cluster already exists this Application type'}
                </Option>
              ))}
          </Select>
        </Space>
        <Text />
        <Text />
        <NewClusterCreation />
      </Space>
    );

    const alertMessage = <Text strong> Move Application </Text>;

    const alertDescription = (
      <Space direction="vertical">
        <Text>
          Moving your application to a different cluster will result in temporary downtime. Please be aware that this action may lead to data loss.
        </Text>
        <Space direction="horizontal">
          <Text> Set Cluster </Text>
          <TipRight tip="Application is created using the cluster, changing the cluster may lead to data loss">
            <UnifieQuestionCircleIcon />
          </TipRight>
        </Space>
        {moveCluster}
      </Space>
    );

    const alertAction = (
      <Button
        onClick={saveDeploymentRegion}
        style={buttonSize}
        danger
        type="dashed"
        disabled={!regionDeployment || regionDeployment === deployment?.region}
      >
        Apply Changes
      </Button>
    );

    return regions && <Alert message={alertMessage} description={alertDescription} action={alertAction} style={bannerStyle} />;
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <AutoDeploySettings deployment={deployment} />
      <PauseApplicationBtn deployment={deployment} />
      {moveApplications()}
      {deleteApplications()}

      <Alert
        message={<Text strong> Template private settings </Text>}
        description={
          <Space direction="vertical">
            <Text>You and your team can use same template for several applications. Additionally you can share your template with whole world.</Text>
            <Text>
              Template id is <CodeLine>{deployment.projectId}</CodeLine>
            </Text>
          </Space>
        }
        action={<ShareProjectModalBtn project={deployment.ProjectModel} />}
        style={bannerStyle}
      />
    </Space>
  );
};
