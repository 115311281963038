import { useState, useEffect } from 'react';
import { Skeleton, Button, notification, Result, Input, Form, Tooltip, Space, Typography } from 'antd';
import { deploymentService, useGqlDeploymentById } from '../../../services/deployment.service';
import { useApiQueryLoop, useUser } from '../../../utils/common';
import { useHistory, useParams } from 'react-router-dom';
import { PoweroffOutlined, SyncOutlined } from '@ant-design/icons';
import { iDeployment, iStatusWebSsh } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { buttonWidth } from 'utils/styles';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { UserNoPermissions } from 'components/Permissions/Permissions';

const { Text } = Typography;

export function DeploymentWebsshPage() {
  // @ts-ignore
  let { id } = useParams();
  const dpQuery = useGqlDeploymentById(id);

  if (dpQuery.loading) {
    return <Skeleton active={true} loading={true} />;
  }

  return <DeploymentWebsshConsole deployment={dpQuery.data?.DeploymentsController_getDeployment} />;
}

export function DeploymentWebsshConsole(props: { deployment: iDeployment }) {
  const history = useHistory();
  const [tunnelData, terror, tloading] = useApiQueryLoop(() => deploymentService.statusWebSsh(props.deployment.id));
  const tunnel: iStatusWebSsh = tunnelData;

  const user = useUser();

  const deployment: iDeployment = props.deployment;
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    terror ? history.push('/') : null;
  }, [terror, history]);

  if (tloading || showProgress) return <Skeleton active={true} loading={true} />;

  if (!userHasPermission(user, UserPermissions.DeploymentWebSSH)) {
    return <UserNoPermissions permission={UserPermissions.DeploymentWebSSH} />;
  }

  if (deployment.isReady === false) {
    return <Result status="warning" title="Deployment is not ready" subTitle={`Please wait for deployment to be ready.`} />;
  }

  const startWebSssh = async () => {
    setShowProgress(true);
    setShowProgress(false);
    const { status, error } = (await deploymentService.startWebSsh(props.deployment.id))?.data || {};
    status
      ? notification.success({ key: 'startWebSsh', message: 'Web SSH deployment started' })
      : notification.error({ key: 'startWebSsh', description: error || 'Error', message: 'Error' });
  };

  const stopWebSsh = async () => {
    const { status, error } = (await deploymentService.stopWebSsh(props.deployment.id))?.data || {};
    status
      ? notification.success({ key: 'stopWebSsh', message: 'Web SSH deployment stopped' })
      : notification.error({ key: 'stopWebSsh', description: error || 'Error', message: 'Error' });
  };

  let status = undefined;
  let icon = undefined;
  let text = null;
  let subTitle = <> Web SSH console allows you to connect to a remote server via SSH. </>;

  if (tunnel?.status === 'running') {
    status = `success`;
    text = `You can open webssh console`;
  } else if (tunnel?.status === 'pending') {
    status = undefined;
    icon = <SyncOutlined spin={true} />;
    text = `Pod in pending state, please wait`;
    subTitle = <> Current pod status {tunnel?.status} </>;
  } else if (tunnel?.tunnelStatus != `ready`) {
    status = undefined;
    icon = <SyncOutlined spin={true} />;
    text = `Tunnel is not ready, Please wait...`;
    subTitle = (
      <Space direction="horizontal">
        <Text> Current Tunnel Status: </Text>
        <Text strong> {JSON.stringify(tunnel?.tunnelStatus || tunnel?.error)} </Text>
      </Space>
    );
  } else if (tunnel?.status === 'not-started') {
    icon = <PoweroffOutlined />;
    text = `SSH Agent is not running`;
    subTitle = <> Web SSH console allows you to connect to a remote server via SSH.</>;
  }

  const goButton = tunnel?.status === 'running' && tunnel?.tunnelStatus === 'ready' && (
    // Note: The form is used to submit the credentials to the terminalURL
    // The terminalURL is the URL of the webssh console
    // The credentials are the login and password
    //
    // Warning:  Never change tag `form` to `Form` from antd - it will not work properly
    //
    <form key="goButton" action={tunnel.terminalURL} method="POST" target="_blank" style={{ display: 'inline-block' }}>
      <Input type="hidden" name="username" value={tunnel.login} />
      <Input type="hidden" name="userpassword" value={tunnel.password} />
      <Input type="hidden" name="header" value="SSH Agent" />

      <Tooltip color="#115EA3" placement="bottom" title="Click here to go to console">
        <Button type="primary" htmlType="submit" className="ant-btn ant-btn-primary">
          Go to console
        </Button>
      </Tooltip>
    </form>
  );

  const startButton = (
    <Tooltip
      color="#115EA3"
      placement="bottom"
      title={tunnel?.status === 'not-started' ? 'Click here to start Web SSH' : 'Click here to restart Web SSH'}
    >
      <Button type="primary" key="startButton" onClick={startWebSssh} style={buttonWidth}>
        {tunnel?.status === 'not-started' ? 'Start Web SSH' : 'Restart Web SSH'}
      </Button>
    </Tooltip>
  );

  const stopButton = tunnel?.status !== 'not-started' && (
    <Tooltip color="#115EA3" placement="bottom" title="Click here to stop Web SSH">
      <Button key="stopButton" onClick={stopWebSsh} style={buttonWidth}>
        Stop Web SSH
      </Button>
    </Tooltip>
  );

  const buttons = [goButton, startButton, stopButton].filter(Boolean);

  return (
    <>
      <DeploymentWidgetsRow deployment={deployment} />
      <Result status={status} icon={icon} title={text} subTitle={subTitle} extra={buttons} />
    </>
  );
}
