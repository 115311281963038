import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TitleUIRow } from 'layout/TitleUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Alert, Button, Collapse, Result, Skeleton, Space, Steps } from 'antd';
import { spaceWidth } from 'utils/styles';
import { useRegionsController_getRegionById } from 'services/regions.service';
import { ClusterDeleteButton } from '../ClusterDangerZone';
import gql from 'graphql-tag';
import { useAuthedQuery } from 'utils/qlAuth';
import { ChangeAWSkeys } from './Tf_ChangeAWSkeys';
import { JobList } from '../jobs/JobList';
import { Tf_AwsEks_JobEditForm } from './Tf_AwsEks_JobEditForm';
import { iJobModel } from 'shared/deployment';
import { JobStatusUI } from './JobStatusUI';
import { JobErrorMessage } from './JobErrorMessage';
import { ChangeAWSkeysAndRunJob } from './ChangeAWSkeysAndRunJob';
import { JobLogs } from '../jobs/JobLogs';
import { ApplyJobButton } from '../jobs/ApplyJobButton';
import { AskToClick } from 'components/SharedComponents/AskToClick/AskToClick';
import { SmileOutlined } from '@ant-design/icons';

export const Tf_AwsEksClusterCreate = (props: any) => {
  const history = useHistory();
  const params: any = useParams();
  const regionId = params.regionId;

  const { loading, region } = useRegionsController_getRegionById(regionId);

  const query = useAuthedQuery(
    gql`
      query JobsController_jobsList($regionId: Int!) {
        JobsController_jobsList(regionId: $regionId) {
          id
          status
          region
          createdAt
          updatedAt
          variables
          finishTime
          createdAt
          tfMode
          name
          lastError
        }
      }
    `,
    { skip: !Number(regionId), variables: { regionId: Number(regionId) }, pollInterval: 5000 },
  );
  query.startPolling(5000);

  useEffect(() => {
    return query.stopPolling;
  }, []);

  if (loading || query.loading) {
    return <Skeleton active />;
  }

  if (region?.configureStatus === 'configured' && region?.integrationsStatus === 'configured') {
    // Cluster is ready - redirect to overview
    history.push(`/clusters/${region.id}/overview`);
    return <Skeleton active={true} loading={true} />;
  }

  if (region?.lastSyncTime && region?.configureStatus === 'configured' && region?.integrationsStatus === 'not-configured') {
    history.push(`/cluster/create/${region.id}/integrations`);
    return <Skeleton active={true} loading={true} />;
  }

  const jobsList = query?.data?.JobsController_jobsList || [];

  const lastJob: iJobModel = jobsList?.[0];
  let editJob: iJobModel = lastJob;

  /**
   * JobInProgress - if any job is in progress
   */
  let JobInProgress = [/*`pre-init`,*/ `pre-run`, `applyd`, `load-logs`].includes(lastJob?.status);
  let JobInError = [`render-error`, `error`, `finished-error`, `canceled`].includes(lastJob?.status);
  let planJobIsDone = lastJob?.status === 'finished';

  /*
  Statuses of the job in the order of appearance:

      'pre-init'
       --> 'render-error'
      'pre-run'
      'applyd'

      'load-logs'
       --> 'error'
      'finished'
       --> 'finished-error'
      'canceled'
  */

  const canShowJobLogs: boolean = ['applyd', 'error', 'load-logs', `finished`, `finished-error`, `canceled`].includes(lastJob?.status);

  let ApplyJobButtonUI = null;
  if (lastJob?.status === 'finished' && lastJob?.tfMode === 'plan') {
    ApplyJobButtonUI = (
      <Result
        status="success"
        title="Cluster configuration plan is ready"
        subTitle="Please review the plan and confirm it."
        extra={[
          <ApplyJobButton
            job={lastJob}
            btnType="primary"
            btnDanger={false}
            btnText="Create cluster acording to terraform plan below"
            onApply={function (jobId: number): void {
              //...
            }}
          />,
        ]}
      />
    );
  }
  if (lastJob?.status === 'finished' && lastJob?.tfMode === 'apply') {
    ApplyJobButtonUI = (
      <Result
        icon={<SmileOutlined />}
        status="success"
        title="Cluster is ready"
        subTitle="You can see the cluster details in the overview page."
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => {
              history.push(`/new-application`);
            }}
          >
            Deploy application in the cluster
          </Button>,
          <Button
            type="default"
            key="console"
            onClick={() => {
              history.push(`/clusters/${region.id}/overview`);
            }}
          >
            Cluster overview
          </Button>,
        ]}
      />
    );
  }

  let title = 'Configure new cluster';
  if (lastJob?.tfMode === 'apply') {
    title = 'Cluster creation';
  }
  if (JobInProgress && lastJob?.tfMode === 'plan') {
    title = 'Planing job in progress';
  }

  return (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title={title} />

      {lastJob.status !== `pre-init` && <JobStatusUI job={lastJob} />}
      {ApplyJobButtonUI}
      {lastJob.status !== `pre-init` && <JobLogs job={lastJob} height={`250px`} />}

      {JobInError && (
        <>
          <JobErrorMessage job={lastJob} />
          <ChangeAWSkeysAndRunJob region={region} editJob={null} onSave={query.refetch} />
        </>
      )}

      {!planJobIsDone && !JobInProgress && !JobInError && (
        <>
          <Collapse defaultActiveKey={[]} bordered={false}>
            <Collapse.Panel
              header="Edit AWS access configuration"
              key="ChangeAWSkeys"
              extra={<i>(In case if you did not done them on previous step)</i>}
            >
              <ChangeAWSkeys region={region} />
            </Collapse.Panel>
          </Collapse>
          <Collapse defaultActiveKey={lastJob.status === `pre-init` ? ['Tf_AwsEks_JobEditForm'] : []} bordered={false}>
            <Collapse.Panel header="Edit initial cluster configuration" key="Tf_AwsEks_JobEditForm">
              <Tf_AwsEks_JobEditForm
                editJob={editJob}
                regionId={region.id}
                onFinish={e => {
                  query.refetch();
                  query.startPolling(5000);
                }}
              />
            </Collapse.Panel>
          </Collapse>
          <BottomButtons children={<ClusterDeleteButton region={region} />}></BottomButtons>
        </>
      )}

      {(query?.data?.JobsController_jobsList || []).find(j => j.status !== `pre-init`) && (
        <Collapse defaultActiveKey={[]} bordered={false}>
          <Collapse.Panel header="List of previously runned jobs" key="Tf_AwsEks_JobEditForm">
            <JobList query={query} refetch={query.refetch} region={region} clusterAreReady={false} />
          </Collapse.Panel>
        </Collapse>
      )}

      {/* <BottomButtons extra={<ClusterDeleteButton region={region} />}></BottomButtons> */}
    </Space>
  );
};
