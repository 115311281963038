import { ReactElement } from 'react';
import { Button, Form, Input, notification, Typography } from 'antd';
import { projectService } from 'services/project.service';
import { useHistory } from 'react-router-dom';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { iDeployment } from 'shared/deployment';
import { buttonBorder } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { DeployButton } from 'components/Deployments/Setting/new-deployment/DeployPage';

const { Text, Title } = Typography;
const { Item } = Form;

export const NewServicePreFill_helm = (props: { serviceName: string; deployment: iDeployment; disabled: boolean }): ReactElement => {
  const history = useHistory();
  const [form] = Form.useForm();

  const handleCreateService = async (e: any): Promise<boolean> => {
    if (props.disabled) {
      return false;
    }

    const name = props.serviceName;
    const projectId = props.deployment?.projectId;
    const newService: iCloudProjectServiceYamlSpecs = {
      name: name,
      uiType: `helm`,
      helmRepositoryName: e.helmRepositoryName,
      helmRepositoryURL: e.helmRepositoryURL,
      helmRepositoryVersion: e.helmRepositoryVersion,
      helmRepositoryChartName: e.helmRepositoryChartName,
    };
    const { error } = await projectService.setNewService(projectId, newService);
    if (error) {
      notification.error({ message: `Error: ${error}` });
      return false;
    }

    notification.success({ message: 'Ready' });
    history.push(`/app/${props.deployment.id}/configuration/services/${name}/main`);
    return true;
  };
  return (
    <>
      <Text>Use if you already have a Helm chart</Text>
      <Form
        form={form}
        disabled={props.disabled}
        layout="horizontal"
        labelCol={{ flex: '220px' }}
        labelWrap={true}
        style={{ maxWidth: 480 }}
        onFinish={handleCreateService}
      >
        <Item
          key="helmRepositoryName"
          name="helmRepositoryName"
          label={<Text strong> Helm chart repository name </Text>}
          rules={[{ required: true }]}
        >
          <Input placeholder="Helm chart repository name" />
        </Item>
        <Item key="helmRepositoryURL" name="helmRepositoryURL" label={<Text strong> Helm chart repository URL </Text>}>
          <Input placeholder="Helm chart repository URL" />
        </Item>
        <Item key="helmRepositoryVersion" name="helmRepositoryVersion" label={<Text strong> Helm chart version </Text>}>
          <Input placeholder="Helm chart version" />
        </Item>
        <Item key="helmRepositoryChartName" name="helmRepositoryChartName" label={<Text strong> Helm chart name </Text>} rules={[{ required: true }]}>
          <Input placeholder="Helm chart name" />
        </Item>
        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save
          </Button>

          <DeployButton
            deployment={props.deployment}
            beforeDeploy={async e => {
              return await handleCreateService(form.getFieldsValue());
            }}
          />
        </BottomButtons>
      </Form>
    </>
  );
};
