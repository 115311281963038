import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'utils/common';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { Button, Form, Input, notification, Select, Skeleton, Tag } from 'antd';
import { buttonWidth, floatingRight } from 'utils/styles';
import { LoadingOutlined } from '@ant-design/icons';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { TitleUIRow } from 'layout/TitleUI';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { iDeployment, iRegionModel } from 'shared/deployment';
import { NewClusterCreation } from 'components/Clusters/ClustersList';
import { updateDeployment } from '../Setting/common';
import { DeleteDeployment } from '../Setting/General/DeleteDeployment';
import { DeployButton } from '../Setting/new-deployment/DeployPage';
import { useGqlDeploymentById } from 'services/deployment.service';

export function OverviewForNotReadyDeployment(props: { deploymentId: number }) {
  const user = useUser();
  const history = useHistory();

  const dpQuery = useGqlDeploymentById(props.deploymentId);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const qTags = useAuthedQuery(
    gql`
      query TagsController_tagsList {
        TagsController_tagsList {
          id
          name
          color
        }
        RegionsController_regionsForNewDeployment {
          id
          tenant
          name
          title
          kubernetesVersion
          cloudProvider
          isPublic
        }
      }
    `,
    {},
  );
  const regions: iRegionModel[] = qTags.data?.RegionsController_regionsForNewDeployment || [];

  if (!dpQuery.loading && !dpQuery.data?.DeploymentsController_getDeployment) {
    debugger;
    notification.error({ message: 'Deployment not found' });
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }

  if (!deployment || !deployment.name || qTags.loading) {
    return <Skeleton active={true} loading={true} />;
  }

  const Save = async () => {
    setLoading(true);
    try {
      const config = form.getFieldsValue();
      await updateDeployment(deployment.id, {
        name: config.name,
        tags: config.tags,
        region: config.region,
      });
      await dpQuery.refetch();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    await Save();
    await dpQuery.refetch();
    if (!deployment.isReady) {
      history.push(`/app/${deployment.id}/configuration/new-service/add`);
    }
  };

  // Not authorized to access this page
  if (!userHasPermission(user, UserPermissions.ProjectsMange)) {
    return null;
  }

  return (
    <>
      <TitleUIRow title={`Create a new application`} />
      <Form
        form={form}
        onFinish={handleSave}
        layout="vertical"
        initialValues={{
          name: deployment?.name,
          tags: deployment?.tags,
          region: deployment?.region,
        }}
        validateTrigger="onSubmit"
        validateMessages={{
          required: '${label} is required!',
        }}
      >
        <Form.Item
          label="Application name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input application name!',
            },
          ]}
        >
          <Input placeholder="Any name" />
        </Form.Item>

        <Form.Item name="tags" label="Application tags">
          <Select mode="multiple" placeholder="Tags">
            {qTags.data.TagsController_tagsList.map(elem => (
              <Select.Option value={elem.id} key={elem.id}>
                <Tag color={elem.color}>{elem.name}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="region" label="Kubernetes cluster">
          <Select placeholder="Choose cluster">
            <Select.Option value={-1} key={0}>
              Connect cluster later
            </Select.Option>
            {regions
              .sort((a, b) => (b.id - a.id !== 0 ? b.id - a.id : Number(a.isPublic) - Number(b.isPublic)))
              .map((v: iRegionModel) => (
                <Select.Option value={v.id} key={v.id} disabled={v.allowToAddDeployments}>
                  <Tag color={v.isPublic ? 'grey' : 'green'} title="Kubernetes version">
                    {v.isPublic ? 'Shared' : 'Private'}
                  </Tag>
                  <Tag color={v.kubernetesVersion ? 'green' : 'red'} title="Kubernetes version" style={{ ...floatingRight, marginTop: 5 }}>
                    Kubernetes {v.kubernetesVersion || 'N/A'}
                  </Tag>
                  {v.title}
                </Select.Option>
              ))}
            <Select.Option key={'New_Cluster'}>
              <TipLeft tip="Select this to create a new cluster">
                <NewClusterCreation />
              </TipLeft>
            </Select.Option>
          </Select>
        </Form.Item>
        <BottomButtons extra={<DeleteDeployment id={deployment.id} />}>
          <Button disabled={loading} type="primary" htmlType="submit" style={buttonWidth}>
            {deployment.isReady ? `Save` : `Save > Add a service`} {loading ? <LoadingOutlined /> : null}
          </Button>
          <DeployButton
            deployment={deployment}
            disabled={loading}
            title={
              <>
                {loading ? <LoadingOutlined /> : null} Save {'&'} Deploy
              </>
            }
            beforeDeploy={async e => {
              await Save();
              return true;
            }}
          />
        </BottomButtons>
      </Form>
    </>
  );
}
