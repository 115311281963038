import 'antd/dist/reset.css';
import './styles/style.scss';
import en_US from 'antd/lib/locale/en_US';
import Routes from './routes/Route';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { authService } from 'services/auth.service';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider, theme } from 'antd';
import { loader } from '@monaco-editor/react';
import { routeConfigAdminLayout } from 'routes/RouteConfig';

if (window.location.host === 'localhost:3000') {
  loadDevMessages();
  loadErrorMessages();
}

const config = { paths: { vs: '/monaco/vs' } };
loader.config(config);

function App() {
  const currentLocale: any = en_US;
  const client = authService.getApolloClient();

  const [userConfigurationContext, setUserConfigurationContextValue] = useState(null);
  authService.setThemeTokenCallback(setUserConfigurationContextValue);

  let appToken = userConfigurationContext?.antThemeToken || {};
  let appTheme = userConfigurationContext?.antThemeName || null;
  let algorithmName = (appTheme || `default-default`).split(`-`)[0] || `default`;
  let algorithmType = (appTheme || `default-default`).split(`-`)[1] || `default`;
  let darks = theme.darkAlgorithm;
  let compacts = theme.compactAlgorithm;
  let defaults = theme.defaultAlgorithm;

  let algorithm =
    appTheme === 'dark'
      ? darks
      : appTheme === 'dark-compact'
      ? [darks, compacts]
      : appTheme === 'default'
      ? [defaults]
      : appTheme === 'default-compact'
      ? [defaults, compacts]
      : undefined;

  let token =
    appTheme === 'custom' || appTheme === 'custom-compact' || appTheme === 'custom-dark' || appTheme === 'custom-dark-compact'
      ? appToken
      : { colorPrimary: '#00A58E', colorInfo: '#ECF8F7', colorLink: '#00A58E' };

  const appComponents = {
    Button: { algorithm: true, groupBorderColor: '#00A58E' },
    Input: { activeBorderColor: '#00A58E', hoverBorderColor: '#00A58E 0.59' },
    Menu: { itemSelectedBg: '#ECECEC', itemSelectedColor: '#00A58E' },
    Select: { optionSelectedBg: '#BFE8E2' },
    Message: { colorInfo: '#00A58E' },
  };

  const app_theme = { token: token, algorithm: algorithm, components: appComponents };

  const bodyClass = `Theme-${appTheme} Algorithm-${algorithmType} Color-${algorithmName}`;
  useEffect(() => {
    if (document?.body) {
      document.body.className = bodyClass;
    }
  }, [bodyClass]);

  return (
    <ConfigProvider locale={currentLocale} theme={app_theme}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes routeConfigAdminLayout={routeConfigAdminLayout} />
        </BrowserRouter>
      </ApolloProvider>
    </ConfigProvider>
  );
}

export default App;
