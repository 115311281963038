import { Link } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
import { LockOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

interface iDeploymentApplyWidget {
  deployment: iDeployment;
}

export const DeploymentApplyWidget = ({ deployment }: iDeploymentApplyWidget) => {
  if (!deployment?.useManualApply) {
    return null;
  }

  return (
    <Link to={`/app/${deployment.id}/status/deploy`}>
      <Tag color={deployment.hasChangesForApply ? 'cyan' : 'default'} icon={<LockOutlined />}>
        Check and deploy
      </Tag>
    </Link>
  );
};
