import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { iUserModel } from 'shared/user';
import { useHistory } from 'react-router-dom';
import { usersService } from 'services/users.service';
import { useApiQuery } from 'utils/common';
import { CustomWidgets } from 'form-components';
import { UserRolesList } from 'shared/UserRolesPermission';
import { useParams } from 'react-router-dom';
import { Button, Modal, Skeleton, Space, notification, Typography, Tabs, Alert, TabsProps } from 'antd';
import { CalendarFilled, DeleteFilled, ExclamationCircleOutlined, KeyOutlined } from '@ant-design/icons';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { formatUserName } from 'utils/nameFormat';
import { UserApiKeys } from './ApiKeys';

const { Text } = Typography;

export interface iUserPageProps {
  userId: number;
}

const EditUserSchemaForm: any = {
  required: ['name', 'email'],
  type: 'object',
  properties: {
    name: { type: 'string', title: 'Name' },
    email: { type: 'string', title: 'Email' },
    password: { type: 'string', title: 'Password' },
    isBlocked: { type: 'boolean', title: 'Blocked' },
    isConfirmed: { type: 'boolean', title: 'Active' },
    roles: { type: 'array', title: 'Roles', uniqueItems: true, items: { type: 'string', enum: UserRolesList } },
  },
};
const EdisUserSchemaFormUI = { roles: { 'ui:widget': 'checkboxes' } };

export const UserPage = (props: iUserPageProps) => {
  const params: any = useParams();
  const userId = Number(params?.userId ? params?.userId : props.userId);
  const history = useHistory();
  const [userData, err, load] = useApiQuery(() => usersService.getUser(userId), [userId]);

  const handleSubmitUserDelete = async (e: any) => {
    const data = e.formData;
    const res = await usersService.updateUser(userId, data);
    const notificationMessage = res.error ? `Error: ${res.error}` : 'Ready';
    notification[res.error ? 'error' : 'success']({ message: notificationMessage });
  };

  if (!userData || err || load) return <Skeleton active={true} loading={true} />;

  const user: iUserModel = userData;

  const deleteUserGeneralLabel = () => (
    <Text>
      <CalendarFilled /> General
    </Text>
  );

  const deleteUserGeneralChildren = () => (
    <Form
      widgets={CustomWidgets}
      formData={user}
      schema={EditUserSchemaForm}
      uiSchema={EdisUserSchemaFormUI}
      validator={validator}
      onSubmit={handleSubmitUserDelete}
      onError={e => console.log(`Error from submit: ${e}`)}
    />
  );

  const deleteUserdangerZoneLabel = () => (
    <Text type="danger">
      <DeleteFilled /> Danger Zone
    </Text>
  );

  const modalContent = (
    <Space direction="vertical">
      <Text>
        You will be deleting the User: <Text strong> {formatUserName(user.email)} </Text>
      </Text>
      <Text strong> {userData?.name || ''} </Text>
    </Space>
  );

  const deleteUserdangerZoneChildren = () => {
    const handleOnClickAddUsers = async () => {
      const handleOnOk = async () => {
        await usersService.deleteUser(userId);
        history.push('/users');
      };
      Modal.confirm({
        title: 'Are you sure you want to Delete this User ?',
        icon: <ExclamationCircleOutlined />,
        content: modalContent,
        onOk: handleOnOk,
      });
    };
    return (
      <Space direction="vertical" style={spaceWidth}>
        <Alert showIcon type="error" message="This action is irreversible, and deletion will lead to the deletion of User." />
        <Text />
        <Button type="primary" danger style={buttonBorder} onClick={handleOnClickAddUsers}>
          Delete User
        </Button>
      </Space>
    );
  };

  const deleteUser: TabsProps['items'] = [
    { key: 'deleteUserGeneral', label: deleteUserGeneralLabel(), children: deleteUserGeneralChildren() },
    {
      key: 'api',
      label: (
        <Text>
          <KeyOutlined /> API keys
        </Text>
      ),
      children: <UserApiKeys userId={userId} />,
    },
    { key: 'deleteUserdangerZone', label: deleteUserdangerZoneLabel(), children: deleteUserdangerZoneChildren() },
  ];

  return <Tabs defaultActiveKey="deleteUserGeneral" items={deleteUser} />;
};
