import gql from 'graphql-tag';
import { useEffect } from 'react';
import { Console } from 'console-feed';
import { useAuthedQuery } from 'utils/qlAuth';
import { iDeployment } from 'shared/deployment';
import { Alert, Skeleton, Space, Typography } from 'antd';
import { spaceWidth, logsStyle, logsText } from 'utils/styles';
import { UnifieConsole } from 'components/SharedComponents/Console/Console';

const { Text } = Typography;

interface iDeploymentMetacontrollerLogsProps {
  deployment: iDeployment;
}

export function DeploymentMetacontrollerLogs(props: iDeploymentMetacontrollerLogsProps) {
  const { loading, error, data, stopPolling, startPolling } = useAuthedQuery(
    gql`
      query DeploymentLogsController_metacontrollerDeploymentLogs($deploymentId: Int!) {
        DeploymentLogsController_metacontrollerDeploymentLogs(deploymentId: $deploymentId) {
          error
          logs {
            method
            data
            pod
          }
        }
      }
    `,
    { variables: { deploymentId: props.deployment.id }, pollInterval: 25000 },
  );
  startPolling(25000);

  useEffect(() => {
    return stopPolling;
  }, []);

  let podLogs = data?.DeploymentLogsController_metacontrollerDeploymentLogs?.logs;
  const agentError = data?.DeploymentLogsController_metacontrollerDeploymentLogs?.error;

  return (
    <Skeleton active={true} loading={loading && !error && !podLogs}>
      <Space direction="vertical" style={spaceWidth}>
        {agentError || (error && <Alert showIcon type="error" message={error?.message || agentError || error || `Something went wrong.`} />)}
        <Text />
        {podLogs && podLogs.length ? (
          <div style={logsStyle}>
            <UnifieConsole logs={podLogs.flat()} />
          </div>
        ) : (
          <Alert showIcon type="warning" message="No logs for your request." />
        )}
      </Space>
    </Skeleton>
  );
}
