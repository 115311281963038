import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { projectService } from 'services/project.service';
import { useApiQuery, useUser } from 'utils/common';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { Button, Form, Input, Modal, notification, Select, Skeleton, Tag } from 'antd';
import { buttonSize, buttonWidth, floatingRight } from 'utils/styles';
import { LoadingOutlined } from '@ant-design/icons';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { TitleUIRow } from 'layout/TitleUI';
import DeploymentFromTemplate, { CreateApplicationFromTemplate } from './DeploymentFromTemplate';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { iProjectModel, iRegionModel } from 'shared/deployment';
import { NewClusterCreation } from 'components/Clusters/ClustersList';

export function PreFillNewDeploymentSettings() {
  const params: any = useParams();
  /**
   * Way of creating a new project
   * `from-public-template` or `from-own-template` or `from-scratch`
   */
  const way = String(params?.way);
  const projectUUid = String(params?.projectUUid);
  const [projectData, fError, fLoader] = useApiQuery(
    () => projectService.getPublicProjectByUUID(projectUUid),
    [projectUUid],
    !!projectUUid && projectUUid != 'undefined',
  );
  const project: iProjectModel = projectData;

  const user = useUser();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [preFillValues, setPreFillValues] = useState<any>({});

  const [form] = Form.useForm();

  const qTags = useAuthedQuery(
    gql`
      query TagsController_tagsList {
        TagsController_tagsList {
          id
          name
          color
        }
        RegionsController_regionsForNewDeployment {
          id
          tenant
          name
          title
          kubernetesVersion
          cloudProvider
          isPublic
        }
      }
    `,
    {},
  );
  const regions: iRegionModel[] = qTags.data?.RegionsController_regionsForNewDeployment || [];

  if (qTags.loading || fLoader) {
    return <Skeleton active={true} loading={true} />;
  }

  const handleCreateProject = async () => {
    const config = form.getFieldsValue();
    setPreFillValues(config);

    if (way === 'from-scratch') {
      const res = await projectService.setNewProject(config);
      if (!res.data) {
        setLoading(false);
        return notification.error({ message: 'Error', description: res.error });
      }
      history.push(`/app/${res.data.deploymentId}/configuration/new-service/add`);
      return;
    }
    if ([`from-public-template`].includes(way)) {
      if (project) {
        // Deploy public template
        await CreateApplicationFromTemplate(project, user, config, history);
        return;
      }
      setShowModal(true);
      return;
    }

    if ([`from-own-template`, `from-public-template`].includes(way)) {
      setShowModal(true);
      return;
    }
  };

  // Not authorized to access this page
  if (!userHasPermission(user, UserPermissions.ProjectsMange)) {
    return null;
  }

  let btnText = `Next > Add a service`;
  let title = 'Create a new project from scratch';
  if (way === 'from-public-template') {
    title = 'Create a new project from public template';
    btnText = `Next > Choose a template`;
    if (project) {
      title = `Deploy public template: ${project?.name}`;
      btnText = `Next > Deploy`;
    }
  }
  if (way === 'from-own-template') {
    title = 'Create a new project by copying an existing one';
    btnText = `Next > Choose a template`;
  }

  let DeploymentFromTemplateModal = null;
  if ([`from-public-template`, `from-own-template`].includes(way)) {
    DeploymentFromTemplateModal = (
      <Modal
        title="Choose application template"
        open={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        footer={null}
        width="95vw"
        style={{ top: 20 }}
      >
        <DeploymentFromTemplate category={way === `from-public-template` ? `all` : `own`} preFillValues={preFillValues} />
      </Modal>
    );
  }

  return (
    <>
      <TitleUIRow title={title} />
      <Form
        form={form}
        onFinish={handleCreateProject}
        layout="vertical"
        initialValues={{
          name: project?.name,
          description: project?.description,
        }}
        validateTrigger="onSubmit"
        validateMessages={{
          required: '${label} is required!',
        }}
      >
        <Form.Item
          label="Application name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input application name!',
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea placeholder="Any description" />
        </Form.Item>

        <Form.Item name="tags" label="Application tags">
          <Select mode="multiple" placeholder="Tags">
            {qTags.data.TagsController_tagsList.map(elem => (
              <Select.Option value={elem.id} key={elem.id}>
                <Tag color={elem.color}>{elem.name}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="region" label="Kubernetes cluster">
          <Select placeholder="Choose kubernetes cluster">
            <Select.Option value={-1} key={0}>
              Connect cluster later
            </Select.Option>
            {regions
              .sort((a, b) => (b.id - a.id !== 0 ? b.id - a.id : Number(a.isPublic) - Number(b.isPublic)))
              .map((v: iRegionModel) => (
                <Select.Option value={v.id} key={v.id} disabled={v.allowToAddDeployments}>
                  <Tag color={v.isPublic ? 'grey' : 'green'} title="Kubernetes version">
                    {v.isPublic ? 'Shared' : 'Private'}
                  </Tag>
                  <Tag color={v.kubernetesVersion ? 'green' : 'red'} title="Kubernetes version" style={{ ...floatingRight, marginTop: 5 }}>
                    Kubernetes {v.kubernetesVersion || 'N/A'}
                  </Tag>
                  {v.title}
                </Select.Option>
              ))}
            <Select.Option key={'New_Cluster'}>
              <TipLeft tip="Select this to create a new kubernetes cluster">
                <NewClusterCreation />
              </TipLeft>
            </Select.Option>
          </Select>
        </Form.Item>
        <BottomButtons
          extra={
            <Button
              disabled={loading}
              onClick={() => {
                history.push('/new-application');
              }}
              style={buttonSize}
            >
              Back
            </Button>
          }
        >
          <Button disabled={loading} type="primary" htmlType="submit" style={buttonWidth}>
            {btnText} {loading ? <LoadingOutlined /> : null}
          </Button>
        </BottomButtons>
      </Form>
      {DeploymentFromTemplateModal}
    </>
  );
}
